/*
 * Copyright 2017-2017 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License").
 * You may not use this file except in compliance with
 * the License. A copy of the License is located at
 *
 *     http://aws.amazon.com/apache2.0/
 *
 * or in the "license" file accompanying this file. This file is distributed
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR
 * CONDITIONS OF ANY KIND, either express or implied. See the License for
 * the specific language governing permissions
 * and limitations under the License.
 */
// import Stack from '@/lib/stack';
import api from '@/lib/api';

const RESSOURCE_NAME = 'scopes';

// Initial state
const initialState = {
  scopes: [],

};

// Getters
const getters = {

};

// Actions
const actions = {
  async loadScopes({ commit }, params = {}) {
    commit('SET_SCOPES_LOADING', true);

    const payload = {
      perPage: params.perPage || this.perPage,
      page: params.page || this.page,
      where: {
        source: params.source,
        source_value: params.source_value || [],
      },
    };

    const response = await api.list(RESSOURCE_NAME, payload);

    commit('SET_SCOPES', response.data.items);

    commit('SET_SCOPES_LOADING', false);
  },
};

// Mutations
const mutations = {
  SET_SCOPES(state, data) {
    state.scopes = state.scopes.concat(data);
  },
  SET_SCOPES_LOADING(state, data) {
    state.loading = data;
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
