<template>
  <v-app>
    <left-navigation />
    <top-navigation />
    <v-main>
      <v-container fluid class="mt-8">
        <router-view />
      </v-container>
      <bottom-navigation />
    </v-main>
     <snack-bar />
  </v-app>
</template>

<script>
import { mapActions } from 'vuex';
import BottomNavigation from './components/Layout/BottomNavigation.vue';
import LeftNavigation from './components/Layout/LeftNavigation.vue';
import SnackBar from './components/Layout/SnackBar.vue';
import TopNavigation from './components/Layout/TopNavigation.vue';

export default {
  name: 'App',

  components: {

    TopNavigation,
    LeftNavigation,
    BottomNavigation,
    SnackBar,
  },

  methods: {
    ...mapActions('user', [
      'loadUserAndCredentials',
    ]),
  },

  created() {
    setTimeout(() => {
      // use setTimeout otherwise query is empty and path /
      const { query } = this.$route;
      if (query && query.a) {
        // console.log('created', 'user from Alertel: do not load credentials');
      } else {
        this.loadUserAndCredentials({ router: this.$router, onSignIn: false });
      }
    }, 1000);
  },
};
</script>
<style lang="scss">
  .theme--light.v-application {
    background-color: #FAFAFA !important;
  }
</style>
