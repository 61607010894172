/*
 * Copyright 2017-2017 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License").
 * You may not use this file except in compliance with
 * the License. A copy of the License is located at
 *
 *     http://aws.amazon.com/apache2.0/
 *
 * or in the "license" file accompanying this file. This file is distributed
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR
 * CONDITIONS OF ANY KIND, either express or implied. See the License for
 * the specific language governing permissions
 * and limitations under the License.
 */
import Stack from '@/lib/stack';
import api from '@/lib/api';

const RESSOURCE_NAME = 'identities';

// Initial state
const initialState = {
  identities: [],
  aIdIdentities: [],
  perPage: 100,
  page: 1,
  // userDetails: {},
  lastResultLength: 0,
  identitiesLoading: false,
  identityLoading: false,
  identity: {
    id: null,
    email: '',
    firstname: '',
    lastname: '',
    // bundle identifiers from WS
    bundles: [],
    // our array of object
    bundlesFront: [],
    enabled: 1,
    // from WS:
    // {"source":"identity","source_value":"876594","key":"codepan","key_value":"150","bundles":[5]}
    scopes: [],
    // build from scopes
    // {"source":"identity","source_value":"876594","key":"codepan","key_value":"150",
    // "bundles":[5],"label":"AGNEAUXDIST",
    // "bundlesFront":[
    // {"id":5,"label":"Admin","description":"Gestion ...",
    // "enabled":1,"created_at":"2021-01-12T15:44:59.000Z","order":3,"default_application":2}]}
    magasins: [],
  },
  identityOriginal: null,
  scopeBundleDel: [],
  isModified: false,
  errors: new Stack(),
  search: null,
};

// Getters
const getters = {
  identitiesIds: (state) => state.identities.map((x) => x.id),
};

/**
 * onSaveProcessEntities: compare backend
 * user and frontend user object and make necessary requests
 * @param {object}  rootState
 * @param {object}  identityOriginal // back
 * @param {object}  identity // front
 */

/**
 * Format scopes bundle for api request
 * @param   {Array}  scopes
 * @param   {Number} identityId
 * @return  {Array}
 */
function formatBundleDataApi(scopes, identityId) {
  return scopes.reduce((acc, s) => {
    const bundles = s.bundlesFront.map((b) => ({
      bundle_id: b.id,
      identity_id: identityId,
      scope: { key: s.key, key_value: s.key_value },
    }));
    return [...acc, ...bundles];
  }, []);
}

/**
 * Format scopes and bundles on scope for api request
 * @param   {Object} identity
 * @param   {Object} identityOriginal
 * @param   {Number} appId
 * @return  {Object}
 */
function apiDataMap(identity, identityOriginal, appId) {
  // filter new scopes
  const scopesDifferences = identity.magasins.filter(
    (m) => !identityOriginal.magasins.some(
      (origin) => origin.key_value === m.key_value
      && origin.key === m.key
      && origin.application_id === m.application_id,
    ),
  );

  // prepare payload for new scopes
  const scopesToCreate = scopesDifferences.map((scope) => ({
    application_id: appId,
    source: 'identity',
    source_value: `${identity.id}`,
    key: scope.key,
    key_value: scope.key_value,
    enabled: true,
  }));

  // filter deleted scopes
  const scopesDeletedDifferences = identityOriginal.magasins.filter(
    (m) => !identity.magasins.some(
      (origin) => origin.key_value === m.key_value
      && origin.key === m.key
      && origin.application_id === m.application_id,
    ),
  );

  // prepare payload for deleted scopes
  const scopesToDelete = scopesDeletedDifferences.map((scope) => ({
    source: 'identity',
    source_value: `${identity.id}`,
    id: scope.id,
  }));

  // filter scopes whith custom role
  const editedBundle = identity.magasins.filter(
    (m) => !identityOriginal.magasins.some(
      (origin) => origin.key_value === m.key_value
      && origin.key === m.key
      && origin.application_id === m.application_id
      && origin.bundlesFront.length === m.bundlesFront.length,
    ),
  );

  // prepare payload for existing scopes with a new role
  const editedBundleOnScope = formatBundleDataApi(editedBundle, identity.id);

  return {
    scopesToCreate,
    scopesToDelete,
    editedBundleOnScope,
  };
}

async function loadIdentityWithEntities(rootState, rootGetters, params) {
  let payload = null;
  // Update (cas où on veut charger une identité pour la modifier)
  let user;
  if (params) {
    payload = {
      id: +params.id,
    };
    const response = await api.get(RESSOURCE_NAME, payload);
    // eslint-disable-next-line prefer-destructuring
    user = response.data;
  } else {
    // Create (cas de la création, remise à zéro du contenu de identity)
    user = {
      email: '',
      firstname: '',
      lastname: '',
      bundles: [],
      enabled: true,
      scopes: [],
      magasins: [],
    };
  }
  const mag = rootGetters['user/magasins'];
  const { bundles } = rootState.applications;
  const { applicationSelected } = rootState.user;
  user.bundlesFront = user.bundles.map((id) => bundles.find((b) => b.id === id))
    .filter((b) => b.default_application === applicationSelected.id);
  if (applicationSelected
    && applicationSelected.scope_meta
    && applicationSelected.scope_meta.length > 0) {
    user.magasins = user.scopes.filter((s) => applicationSelected.scope_meta.includes(s.key))
      .map((s) => {
        const magasin = mag.find((m) => m.key === s.key && m.key_value === s.key_value);
        if (magasin) {
          let bundlesFront;
          if (s.bundles) {
            bundlesFront = s.bundles.map((id) => bundles.find((b) => b.id === id));
            if (bundlesFront.length > 0) {
              bundlesFront = bundlesFront.filter(
                (b) => b && b.default_application === applicationSelected.id,
              );
              if (bundlesFront.length === 0) {
                // entity on another application (not on applicationSelected)
                return undefined;
              }
            }
          } else {
            bundlesFront = [];
          }
          return { ...s, label: magasin.label, bundlesFront };
        }
        return undefined;
      }).filter((m) => m);
  } else {
    user.magasins = [];
  }
  return user;
}

// Actions
const actions = {
  async updateSearch({ commit, dispatch }, params) {
    commit('SET_SEARCH', params);
    dispatch('loadIdentities');
  },
  async loadIdentities({ commit, rootState, state }, params = {}) {
    commit('SET_IDENTITIES_LOADING', true);
    const { magasinSelected, applicationSelected } = rootState.user;
    // const { bundlesByApp } = rootState.applications;
    // TODO : request identities with default application
    const payload = {
      perPage: params.perPage || state.perPage,
      page: params.page || 1,
      orderBy: 'email',
      where: {
        selected_application: applicationSelected.id,
        // 'bundle.default_application': applicationSelected.id,
        // 'scope.application_id': applicationSelected.id,
        // 'bundle.id': {
        //   source: 'bundle.id',
        //   operator: 'in',
        //   value: bundlesByApp[applicationSelected.id].map((e) => e.id),
        // },
      },
    };

    if (magasinSelected) {
      payload.where = {
        ...payload.where,
        'scope.key': magasinSelected.key,
        'scope.key_value': magasinSelected.key_value,
      };
    }
    if (state.search) {
      payload.where = {
        ...payload.where,
        'identity.email': {
          source: 'email',
          operator: 'like',
          value: `%${state.search}%`,
        },
      };
    }

    try {
      const response = await api.list(RESSOURCE_NAME, payload);
      if (response.data.page === 1) {
        commit('SET_IDENTITIES', response.data.items);
      } else {
        commit('ADD_IDENTITIES', response.data.items);
      }
      commit('SET_IDENTITIES_PAGE', response.data.page);
      commit('SET_IDENTITIES_PER_PAGE', response.data.perPage);
      commit('SET_IDENTITIES_LOADING', false);
    } catch (error) {
      commit('SET_IDENTITIES', null);
      commit('SET_IDENTITIES_LOADING', false);
      commit('ADD_ERROR', error);
    }
  },
  async loadMore({ dispatch, state }) {
    await dispatch('loadIdentities', { page: state.page + 1 });
  },
  async resetPwd({ state, commit }) {
    const { id } = state.identity;
    await api.post(RESSOURCE_NAME, 'reset-password', id);
    commit(
      'notification/SET_SNACK_DATAS',
      {
        text: 'Nouveau mot de passe envoyé',
        color: 'success',
      },
      { root: true },
    );
  },
  // Toggle enable/soft-delete/disable
  async toggleIdentity({ commit, state, rootState }, params = {}) {
    commit('SET_IDENTITY_LOADING', true);
    // Get the targeted identity
    const target = params.identity;
    const isSoftDelete = params.soft;
    // Get the selected app
    const { applicationSelected } = rootState.user;

    let payload = target.id;

    let response;
    try {
      if (isSoftDelete) {
        payload = { id: target.id, application_id: applicationSelected.id };
        response = await api.softDelete(RESSOURCE_NAME, payload);
        commit('SET_IDENTITY_LOADING', false);
        const { isIdentityDisabled, profiles, scopes } = response.data;
        let msg;
        let color = 'success';
        if (isIdentityDisabled) {
          msg = 'Utilisateur désactivé';
        } else if (profiles.deleted > 0) {
          msg = `Utilisateur supprimé de ${applicationSelected.label}`;
        } else if (scopes.deleted > 0) {
          msg = 'Utilisateur supprimé de vos magasins';
        } else {
          msg = "L'utilisateur n'a pu être supprimé";
          color = 'warning';
        }
        commit(
          'notification/SET_SNACK_DATAS',
          {
            text: msg,
            color,
          },
          { root: true },
        );
      } else if (target.enabled || target.enabled === 1) {
        response = await api.disable(RESSOURCE_NAME, payload);
        const index = state.identities.findIndex((i) => i.id === payload);
        if (index !== -1) {
          state.identities.splice(index, 1);
        }
        commit(
          'notification/SET_SNACK_DATAS',
          {
            text: 'Utilisateur désactivé',
            color: 'success',
          },
          { root: true },
        );
      } else {
        response = await api.enable(RESSOURCE_NAME, payload);
        commit('ADD_IDENTITIES', response.data);
      }
      commit('SET_IDENTITY_LOADING', false);
    } catch (error) {
      commit('SET_IDENTITY_LOADING', false);
      commit('ADD_ERROR', error);
    }
  },

  async loadIdentity({ commit, rootGetters, rootState }, params = {}) {
    commit('SET_IDENTITY_LOADING', true);
    const user = await loadIdentityWithEntities(rootState, rootGetters, params);

    commit('SET_IDENTITY', user);
    commit('SET_IDENTITY_LOADING', false);
    commit('SET_MODIFIED', false);
  },
  async save({
    commit, state, dispatch, rootState, rootGetters,
  }) {
    try {
      const { applicationSelected } = rootState.user;
      const hasAllPermissions = await dispatch('user/hasPermissionIn', ['scopes.list-all'], { root: true });
      const scopes = state.identity.magasins.map(
        (m) => ({ key: m.key, key_value: m.key_value }),
      );
      if (!hasAllPermissions && scopes.length === 0) {
        commit(
          'notification/SET_SNACK_DATAS',
          {
            text: 'Veuillez sélectionner au moins une autorisation',
            color: 'error',
          },
          { root: true },
        );
      } else {
        commit('SET_IDENTITY_LOADING', true);
        if (!state.identity.id) {
          const {
            enabled, email, firstname, lastname,
          } = state.identity;
          if (state.identity.bundlesFront.length === 0) {
            commit(
              'notification/SET_SNACK_DATAS',
              {
                text: 'Veuillez sélectionner un rôle',
                color: 'error',
              },
              { root: true },
            );
          } else {
            const { bundlesFront } = state.identity;
            const payload = {
              enabled, email, firstname, lastname, bundle_id: bundlesFront[0].id, scopes,
            };
            // create identity, default roles and scopes
            const response = await api.create(RESSOURCE_NAME, payload);
            state.identity.id = response.data.id;
            // load user once created
            const user = await loadIdentityWithEntities(
              rootState,
              rootGetters,
              { id: state.identity.id },
            );

            // if it is a multi role user, create all other roles
            if (bundlesFront.length > 1) {
              // could do an array.slice here...
              const multiBundle = [...bundlesFront];
              multiBundle.shift();
              await Promise.all(multiBundle.map(async (mb) => {
                await api.create('bundles-identities', { bundle_id: mb.id, identity_id: state.identity.id });
              }));
            }
            // Update identityOriginal for front/back scopes comparison
            const identityOriginal = user; // user from back
            const { identity } = state;
            const {
              editedBundleOnScope,
            } = apiDataMap(identity, identityOriginal, applicationSelected.id);

            // user has another role on a scope than the default one
            if (editedBundleOnScope.length) {
              await Promise.all(editedBundleOnScope.map(async (b) => {
                await api.create('bundles-identities', b);
              }));
            }
          }
        } else {
          // User edition
          if (state.identity.firstname !== state.identityOriginal.firstname
            || state.identity.lastname !== state.identityOriginal.lastname) {
            const {
              firstname, lastname, id,
            } = state.identity;
            const i = {
              firstname, lastname,
            };
            await api.update(RESSOURCE_NAME, id, i);
          }
          // magasins
          const { identity, identityOriginal } = state;
          // eslint-disable-next-line no-use-before-define
          // look for difference between data from backend and data from frontend
          // and process the data for api
          const {
            scopesToCreate,
            editedBundleOnScope,
            scopesToDelete,
          } = apiDataMap(identity, identityOriginal, applicationSelected.id);

          // create scope
          if (scopesToCreate.length) {
            await Promise.all(scopesToCreate.map((scope) => api.create('scopes', scope)));
          }

          // is there an custom role on a scope ?
          if (editedBundleOnScope.length) {
            await Promise.all(editedBundleOnScope.map((b) => api.create('bundles-identities', b)));
          }

          // is there any deleted scope ?
          if (scopesToDelete.length) {
            await Promise.all(scopesToDelete.map((scope) => api.hardDelete('scopes', scope)));
          }

          const scopeBundlesToDelete = formatBundleDataApi(state.scopeBundleDel, identity.id);
          if (state.scopeBundleDel.length) {
            await Promise.all(scopeBundlesToDelete.map((sBundle) => api.hardDelete('bundles-identities', sBundle)));
            commit('RESET_SCOPE_BUNDLE_DEL');
          }

          // Roles edition
          const newRole = state.identity.bundlesFront.filter((b) => (
            !state.identityOriginal.bundlesFront.some((origin) => origin.id === b.id)
          ));
          await Promise.all(newRole.map((role) => api.create('bundles-identities', {
            identity_id: state.identity.id,
            bundle_id: role.id,
          })));

          const deletedRole = state.identityOriginal.bundlesFront.filter((b) => (
            !state.identity.bundlesFront.some((origin) => origin.id === b.id)
          ));

          await Promise.all(deletedRole.map((role) => api.hardDelete('bundles-identities', {
            identity_id: state.identity.id,
            bundle_id: role.id,
          })));

          commit(
            'notification/SET_SNACK_DATAS',
            {
              text: 'Votre modification est prise en compte. Elle sera effective sous 15 minutes.',
              color: 'success',
            },
            { root: true },
          );
        }

        if (state.identity.id) {
          // user stays at /identities/create
          // TODO: redirect to identity page once we have an ID: /identity/${state.identity/id}
          dispatch('loadIdentity', { id: state.identity.id });
        } else {
          commit('SET_IDENTITY_LOADING', false);
        }
      }
    } catch (e) {
      // the error is displayed by api.js
      console.error(e);
      commit('SET_IDENTITY_LOADING', false);
    }
  },

  removeBundleIdentity({ commit }, params = {}) {
    if (params.index > -1) {
      commit('REMOVE_IDENTITY_BUNDLES', params.index);
      commit('SET_MODIFIED', true);
    }
  },
  setModified({ commit }, params = {}) {
    commit('SET_MODIFIED', params);
  },

  addBundleIdentity({ commit }, params = {}) {
    commit('ADD_IDENTITY_BUNDLE', params);
    commit('SET_MODIFIED', true);
  },

  addDeletedBundles({ commit, state }, payload) {
    const scopeBundles = state.identityOriginal.magasins.find((s) => s.id === payload.id);
    commit('SET_SCOPE_BUNDLE_DEL', scopeBundles);
  },

  // async loadUserDetails({ commit }, params = {}) {
  //   const { data } = await api.get(RESSOURCE_NAME, params);
  //   console.log('userDetails', data);
  //   commit('SET_USER_DETAILS', data);
  // },
};

// Mutations
const mutations = {
  SET_IDENTITIES(state, data) {
    state.identities = data;
  },
  ADD_IDENTITIES(state, data) {
    const dataNew = data.filter((i) => !state.identities.some((existing) => existing.id === i.id));
    state.identities = state.identities.concat(dataNew);
  },
  SET_IDENTITIES_LOADING(state, data) {
    state.identitiesLoading = data;
  },
  SET_IDENTITIES_PAGE(state, data) {
    state.page = data;
  },
  SET_IDENTITIES_PER_PAGE(state, data) {
    state.perPage = data;
  },
  SET_IDENTITY(state, data) {
    state.identity = data;
    state.identityOriginal = { ...data };
    state.identityOriginal.magasins = data.magasins.map(
      (m) => ({ ...m, bundlesFront: [...m.bundlesFront] }),
    );
    state.identityOriginal.bundlesFront = [...data.bundlesFront];
  },
  SET_IDENTITY_LOADING(state, data) {
    state.identityLoading = data;
  },
  REMOVE_IDENTITY_BUNDLES(state, data) {
    const myArray = state.identity.bundlesFront;

    if (myArray.length === 1) {
      state.identity.bundlesFront.pop();
    } else {
      myArray.splice(data, 1);
      state.identity.bundlesFront = myArray;
    }
  },
  ADD_IDENTITY_BUNDLE(state, data) {
    state.identity.bundlesFront = data;
  },
  ADD_ERROR(state, data) {
    state.errors.add(data);
  },
  // Set the isModified data to know wether the identity has been updated on client side
  SET_MODIFIED(state, data) {
    state.isModified = data;
  },
  SET_SEARCH(state, data) {
    state.search = data;
  },
  SET_USER_DETAILS(state, data) {
    state.userDetails = data;
  },
  SET_SCOPE_BUNDLE_DEL(state, data) {
    state.scopeBundleDel = [...state.scopeBundleDel, data];
  },
  RESET_SCOPE_BUNDLE_DEL(state) {
    state.scopeBundleDel = [];
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
