<template>
  <v-autocomplete
    v-model="magasinSelected"
    v-if="user"
    :items="storesSorted"
    :auto-select-first="true"
    label="Entité"
    color="primary"
    item-color="secondary"
    hide-details
    dense
    offset-y
    :filled="filled"
    outlined
    class="rounded"
    item-text="text"
    return-object
    @input="input"
    data-test="s-navBarShop"
  >
    <template v-slot:[`selection`]="{ item }">
      <v-icon left> mdi-store </v-icon>
      <span > {{ text(item)  }} </span>
    </template>

  </v-autocomplete>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  Name: 'NavBarShopSelect',
  props: {
    value: Object,
    canExtract: Boolean,
    filled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      magasinSelected: this.value,
    };
  },
  computed: {
    ...mapState({
      store: (state) => state.targets.currentStore,
      user: (state) => state.user.cognito,

      citySelected: (state) => state.user.citySelected,
      applicationSelected: (state) => state.user.applicationSelected,
      entityCodesByKeyThenCity: (state) => state.scopesMeta.entityCodesByKeyThenCity,

      // stores: (state) => state.targets.targets,
    }),
    ...mapGetters('user', [
      'magasins',
      'permissions']),

    storesSorted() {
      let { magasins } = this;
      if (this.citySelected && this.applicationSelected.scope_meta.length > 0) {
        const result = this.applicationSelected.scope_meta.map(
          (key) => {
            if (this.entityCodesByKeyThenCity[key]
            && this.entityCodesByKeyThenCity[key][this.citySelected]) {
              return this.entityCodesByKeyThenCity[key][this.citySelected];
            }
            return [];
          },
        ).reduce((acc, array) => acc.concat(array));

        const entityCodes = Object.values(result);
        magasins = magasins.filter(
          (s) => entityCodes.some((c) => c.key === s.key && c.key_value === s.key_value),
        );
      }
      let mags = magasins;
      if (this.canExtract) {
        mags = [
          {
            description: null,
            id: null,
            key: 'all',
            key_value: 'all_ext',
            label: 'Tous - Externes',
            subkey: null,
            text: 'Tous - Externes',
          },
          ...magasins,
        ];
      }
      if (this.permissions['scopes.list-all']) {
        return mags.map((s) => ({ ...s, text: this.text(s) }));
      }
      const appFilteredMags = mags.filter((m) => m.application_id === this.applicationSelected.id);
      return appFilteredMags
        .map((s) => ({ ...s, text: this.text(s) }));
    },

  },

  methods: {
    input(e) {
      this.$emit('input', e);
    },

    text(item) {
      if (item.key === 'codepan') {
        return `${item.key_value} - ${item.label}`;
      }
      return item.label;
    },

  },
};
</script>
