/*
 * Copyright 2017-2017 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License").
 * You may not use this file except in compliance with
 * the License. A copy of the License is located at
 *
 *     http://aws.amazon.com/apache2.0/
 *
 * or in the "license" file accompanying this file. This file is distributed
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR
 * CONDITIONS OF ANY KIND, either express or implied. See the License for
 * the specific language governing permissions
 * and limitations under the License.
 */
import Stack from '@/lib/stack';
import api from '@/lib/api';

const RESOURCE_NAME = 'profiles';
const VERSION = 'v1';
const PATH = `${VERSION}/${RESOURCE_NAME}`;

// Initial state
const initialState = {
  profiles: null,
  loading: null,
  errors: new Stack(),
};

// Getters
const getters = {

};

// Actions
const actions = {
  async loadProfiles({ commit }, params = {}) {
    commit('SET_PROFILES_LOADING', true);

    const payload = {
      method: 'GET',
      params,
    };

    const response = await api
      .from(PATH)
      .request(payload);

    commit('SET_PROFILES', response.data);

    commit('SET_PROFILES_LOADING', false);
  },
};

// Mutations
const mutations = {
  SET_PROFILES(state, data) {
    state.profiles = data;
  },
  SET_PROFILES_LOADING(state, data) {
    state.loading = data;
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
