import axios from 'axios';
import b64 from '@/lib/base64';

const apiEndpoint = process.env.VUE_APP_API_ENDPOINT;
// const version = process.env.VUE_APP_API_VERSION;

let store = {};
const headers = {};
const request = axios.create({
  baseURL: `${apiEndpoint}/`,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
  },
  responseType: 'json',
});

request.interceptors.request.use(
  (config) => (config),
  (error) => {
    console.error('request', error);
    return Promise.reject(error);
  },
);

request.interceptors.response.use(
  (response) => {
    localStorage.setItem('session_lastRequest_date', Date.now());
    return response;
  },
  (err) => {
    // if (err && err.response && err.response.status === 401) {
    //   redirectToCognito();
    // }
    let message;
    if (err.response) {
      message = err.response.data ? err.response.data.message : err.response.data;
      if (!message) {
        message = err.response.headers['x-amzn-errortype'];
        if (message) {
          message = `Une erreur est survenue: ${message}`;
        } else {
          message = err.response;
        }
      }
    } else {
      message = 'Une erreur est survenue';
    }
    store.commit(
      'notification/SET_SNACK_DATAS',
      {
        text: message,
        color: 'error',
      },
    );

    //  store.commit('setLoading', {
    //   [name]: false,
    // });
    console.error(message);
    return Promise.reject(err);
  },
);

const genericApi = () => ({

  connectStore(storeI) {
    store = storeI;
  },

  authenticate(token) {
    // console.log('Authenticate avec token : ', token);
    // TODO
    // FOR TESTS, TO CHANGE BEFORE PROD !!
    headers.Authorization = token;
    // console.log('Token : ', token);
    // headers.Authorization = 'acavat@banque-edel.fr';
  },

  // GET ONLY ONE ITEM..
  get(resource, payload = {}) {
    const query = b64.encryptJsonToBase64(payload);
    return request.get(`${resource}/${query}`, { headers });
  },

  // GET ALL THE ITEMS
  list(resource, payload = {}) {
    const query = b64.encryptJsonToBase64(payload);
    return request.get(`${resource}?query=${query}`, { headers });
  },

  create(resource, data = {}) {
    return request.post(`${resource}`, data, { headers });
  },

  // Payload : qui est-ce qu'on update, data : quelles nouvelles valeurs..
  update(resource, payload = {}, data = {}) {
    const query = b64.encryptJsonToBase64(payload);
    return request.put(`${resource}/${query}`, data, { headers });
  },

  updateSelf(resource, data = {}) {
    return request.put(`${resource}`, data, { headers });
  },

  hardDelete(resource, payload = {}) {
    const query = b64.encryptJsonToBase64(payload);
    return request.post(`${resource}/${query}/delete`, undefined, { headers });
  },

  enable(resource, id) {
    const query = b64.encryptJsonToBase64({ id });
    return request.post(`${resource}/${query}/enable`, undefined, { headers });
  },

  disable(resource, id) {
    const query = b64.encryptJsonToBase64({ id });
    return request.post(`${resource}/${query}/disable`, undefined, { headers });
  },

  softDelete(resource, payload) {
    const query = b64.encryptJsonToBase64(payload);
    return request.post(`${resource}/${query}/soft-delete`, undefined, { headers });
  },

  post(resource, action, id, data) {
    let urlEnd;
    if (id) {
      const query = b64.encryptJsonToBase64({ id });
      urlEnd = `${query}/${action}`;
    } else {
      urlEnd = action;
    }
    return request.post(`${resource}/${urlEnd}`, data, { headers });
  },

  genericFunction(resource, action, id) {
    const query = b64.encryptJsonToBase64({ id });
    return request.post(`${resource}/${query}/${action}`, undefined, { headers });
  },

});

export default genericApi();
