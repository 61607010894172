<template>
  <v-navigation-drawer
    v-if="user"
    expand-on-hover
    :mini-variant="true"
    permanent
    clipped
    app
    data-test="leftMenu">
    <!-- <template v-slot:prepend>
      <left-nav-user-menu />
      <v-divider class="mb-2 grey lighten-2"></v-divider>
    </template> -->
    <v-list nav dense data-test="leftMenuListItem">
      <v-list-item-group v-model="selectedItem">
        <v-list-item
          v-for="item in items"
          :key="item.title"
          :to="item.path"
          link
          :data-test="'menu-' + item.title"
          class="px-2"
          color="primary"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import routes from '@/router/routesLeftMenu';
// import LeftNavUserMenu from '../User/LeftNavUserMenu.vue';

export default {
  Name: 'LeftNavigation',
  // components: {
  //   LeftNavUserMenu,
  // },
  data() {
    return {
      drawer: true,
      items: [],
      selectedItem: 0,
      // [
      // { title: this.$t('menu.users'), icon: 'mdi-face', to: '/identities' },
      // { title: this.$t('menu.applications'), icon: 'mdi-application', to: '/applications' },
      // { title: this.$t('menu.bundles'),
      // icon: 'mdi-briefcase-account-outline', to: '/bundles' },
      // { title: this.$t('menu.profiles'), icon: 'mdi-account', to: '/profiles' },
      // { title: this.$t('menu.permissions'), icon: 'mdi-lock-check', to: '/permissions' },
      // ],
      mini: true,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user.cognito,
      hiUser: (state) => state.user.hiUser,
    }),
  },
  methods: {
    ...mapActions('user', ['hasPermissionIn', 'signOut']),
    async updateMenu() {
      this.items = (
        await Promise.all(
          routes.map(async (r) => {
            if (r.meta && r.meta.permissions) {
              const ok = await this.hasPermissionIn(r.meta.permissions);
              return ok ? r : undefined;
            }
            return r;
          }),
        )
      )
        .filter((r) => r)
        .map((r) => ({ ...r, title: this.$t(r.title) }));
    },
  },
  created() {
    this.updateMenu();
  },
  watch: {
    hiUser() {
      this.updateMenu();
    },
  },
};
</script>

<style>
#app .v-navigation-drawer__border {
  border-right: 1px solid #e0e0e0;
}

.orange-top-border {
  border-top: solid 4px orange;
}
</style>
