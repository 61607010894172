import api from '@/lib/api';
import Stack from '@/lib/stack';

const RESOURCE_NAME = 'applications';
const VERSION = 'v1';
const PATH = `${VERSION}/${RESOURCE_NAME}`;

const initialState = {
  // {"id":2,"label":"Alertel","enabled":1,"created_at":"2021-01-08T16:15:58.000Z","is_mono_bundle":1,"url":"https://d1kodp8s38ek05.cloudfront.net/",
  // "url_label":"Alertel","scope_meta":["codepan","central","galec"],
  // "profiles":[{"id":2,"application_id":2,"label":"Superadmin",
  // "description":null,"enabled":1,"created_at":"2021-01-08T16:16:00.000Z"}]}
  applications: [],
  // {"id":5,"label":"Admin","description":"Gestion","enabled":1,
  // "created_at":"2021-01-12T15:44:59.000Z","order":30,
  // "default_application":2,"target_application":null,"email_application":null}
  bundles: [],
  // {"2":[{"id":5,"label":"Admin","description":"  alertes","enabled":1,
  // "created_at":"2021-01-12T15:44:59.000Z","order":30,"default_application":2,
  // "target_application":null,"email_application": ...
  bundlesByApp: {},
  errors: new Stack(),
  loading: false,
};

const actions = {

  async loadbundlesList({ commit, state }) {
    // TODO for multi app
    // const response = await api.list('bundles-profiles');
    // commit('SET_BUNDLES_BY_APP', response.data);
    const response = await api.list('bundles');
    commit('SET_BUNDLES', response.data.items);

    response.data.items.sort((a, b) => a.label.localeCompare(b.label));
    const bundlesByApp = {};
    state.applications.forEach((app) => {
      bundlesByApp[app.id] = response.data.items.filter((b) => b.default_application === app.id);
    });
    commit('SET_BUNDLES_BY_APP', bundlesByApp);
  },

  async load({ commit, dispatch }) {
    commit('SET_LOADING', true);

    try {
      const response = await api.list(RESOURCE_NAME, { perPage: 0 });

      commit('SET_APPLICATIONS', response.data.items);
      await dispatch('loadbundlesList');
    } catch (err) {
      commit('ADD_ERROR', err);
    }
    commit('SET_LOADING', false);
  },
  async save({ commit }, params = {}) {
    commit('SET_LOADING', true);
    const { body } = params;

    try {
      const response = await api
        .from(PATH)
        .post('/', body);

      commit('APPEND_NEW', response.data);
    } catch (err) {
      commit('ADD_ERROR', err);
    }
    commit('SET_LOADING', false);
  },
};

const mutations = {
  ADD_ERROR(state, error) {
    state.loading = false;
    state.errors.add(error);
  },
  SET_LOADING(state, data) {
    state.loading = data;
  },
  SET_APPLICATIONS(state, data) {
    state.applications = data;
  },
  APPEND_NEW(state, data) {
    state.applications.push(data);
  },
  SET_BUNDLES(state, data) {
    state.bundles = data;
  },
  SET_BUNDLES_BY_APP(state, data) {
    state.bundlesByApp = data;
  },
};

const getters = {};

export default {
  namespaced: true,
  state: initialState,
  actions,
  getters,
  mutations,
};
