<template>

      <v-card>
        <v-card-title class="headline">
          Conditions du site Habilitations
        </v-card-title>
        <v-card-text>

<p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
line-height:115%'>La plateforme Edel Habilitations est un service proposé par
Banque Edel, établissement de crédit agréé par l’Autorité de Contrôle
Prudentiel et de Résolution et filiale du Groupement d’Achat des Centres
E.Leclerc, dont le siège se situe 60 rue Buissonnière – CS 17601 – 31676 Labège
Cedex, immatriculée au RCS de Toulouse sous le numéro 306&nbsp;920&nbsp;109.</span></p>

<p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
line-height:115%'>La plateforme Edel Habilitations est un service de la marque
Edel Payment Services, propriété de la Banque Edel.</span></p>

<p class=MsoCommentText style='text-align:justify'>Par l’accès au Service et
son utilisation vous reconnaissez remplir les conditions pour bénéficier de la
qualité d’Utilisateur telle que définie à l’Article 1. Vous concluez un accord
contractuel (ci-après «&nbsp;l’Accord&nbsp;»).</p>

<p class=ppa style='margin-left:0cm;text-align:justify;text-indent:0cm'><span
style='font-family:"Calibri",sans-serif'>&nbsp;</span></p>

<div style='border:none;border-bottom:solid windowtext 1.0pt;padding:0cm 0cm 1.0pt 0cm'>

<p class=MsoNormal style='border:none;padding:0cm'><span style='font-size:10.0pt;
line-height:115%;color:#1F497D'>Article 1 - Définition</span></p>

</div>

<p class=MsoNormal style='text-align:justify'><b><span style='font-size:10.0pt;
line-height:115%'>Accord</span></b></p>

<p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
line-height:115%'>Désigne les présentes CGU régissant la relation entre Banque
Edel d’une part et le Client et les Utilisateurs d’autre part.</span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-size:10.0pt;
line-height:115%'>Administrateur</span></b></p>

<p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
line-height:115%'>L’ADMINISTRATEUR est habilité par le SUPER ADMINISTRATEUR à
accéder à la plateforme Edel Habilitations lui permettant ainsi (i) de créer
lui-même des sous habilitations opérationnelles sur les seuls Applicatifs
Produit et (ii) d’accéder à un ou plusieurs Applicatifs Produit rattaché(s) à
une ou plusieurs entités entrant dans le périmètre de son activité
professionnelle.</span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-size:10.0pt;
line-height:115%'>Applicatif Produit</span></b></p>

<p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
line-height:115%'>Désigne les environnements propres à chaque service dont
bénéficie le Client et dont les fonctionnalités permettant la consultation
d’informations ou la réalisation d’Opérations sont accessibles par la plateforme
Edel Habilitations.</span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-size:10.0pt;
line-height:115%'>Clients</span></b></p>

<p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
line-height:115%'>Désigne toute entité juridique indépendante ayant souscrit ou
bénéficiant de services auprès de Banque Edel.</span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-size:10.0pt;
line-height:115%'>Opération </span></b></p>

<p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
line-height:115%'>Désigne toute opération d’habilitation permise par la
plateforme Edel Habilitations ainsi que toute opération permise par les
Applicatifs Produit (consultation de reporting, extraction de données, …) et
spécifiées dans les contrats et/ou CGU afférents.</span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-size:10.0pt;
line-height:115%'>Plateforme Edel Habilitations</span></b></p>

<p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
line-height:115%'>Désigne la plateforme, objet des présentes CGU, permettant à
l’Utilisateur (i) de consulter ou réaliser des Opérations au titre de services proposés
par Banque Edel et (ii) de déléguer cette faculté au sein de son entreprise
suivant des profils d’habilitation propres à chaque Applicatif Produit.</span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-size:10.0pt;
line-height:115%'>Super administrateur</span></b></p>

<p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
line-height:115%'>Le SUPER ADMINISTRATEUR est le Représentant Légal du Client
et a accès à l’ensemble des Applicatifs Produit ainsi qu’aux documentations et
fonctionnalités afférentes.</span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-size:10.0pt;
line-height:115%'>Utilisateur</span></b></p>

<p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
line-height:115%'>Désigne le représentant légal du Client utilisateur (ci-après
«&nbsp;le Représentant Légal ») en sa qualité de SUPER ADMINISTRATEUR et toute
personne habilitée par ses soins en qualité d’ADMINISTRATEUR, amenés à
consulter ou effectuer des Opérations via la plateforme Edel Habilitations. </span></p>

<p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
line-height:115%'>&nbsp;</span></p>

<div style='border:none;border-bottom:solid windowtext 1.0pt;padding:0cm 0cm 1.0pt 0cm'>

<p class=MsoNormal style='border:none;padding:0cm'><span style='font-size:10.0pt;
line-height:115%;color:#1F497D'>Article 2 - Description du service</span></p>

</div>

<p class=ppa style='margin-left:0cm;text-align:justify;text-indent:0cm'><span
style='font-family:"Calibri",sans-serif'>La plateforme Edel Habilitations est
la propriété de Banque Edel, destinée aux Clients de Banque Edel.</span></p>

<p class=ppa style='margin-left:0cm;text-align:justify;text-indent:0cm'><span
style='font-family:"Calibri",sans-serif'>La plateforme Edel Habilitations est
une plateforme multiservice permettant au SUPER ADMINISTRATEUR de gérer en
autonomie les niveaux d’accès aux différents services rattachés de Banque Edel au
sein de son entreprise. </span></p>

<p class=ppa style='margin-left:0cm;text-align:justify;text-indent:0cm'><span
style='font-family:"Calibri",sans-serif'>L’accès à la plateforme Edel
Habilitations permet également la consultation et/ou la réalisation d’Opérations
par un Utilisateur au titre de différents services fournis par Banque Edel
que la plateforme Edel Habilitations centralise (ci-après le Service).</span></p>

<p class=ppa style='margin-left:0cm;text-align:justify;text-indent:0cm'><span
style='font-family:"Calibri",sans-serif'>Les conditions d’utilisation relatives
aux services accessibles via la plateforme Edel Habilitations seront traitées
soit par les contrats formalisés entre le Client et Banque Edel soit par
des Conditions Générales d’Utilisation accessibles sur chaque Applicatif
Produit.</span></p>

<p class=ppa style='margin-left:0cm;text-align:justify;text-indent:0cm'><span
style='font-family:"Calibri",sans-serif'>&nbsp;</span></p>

<div style='border:none;border-bottom:solid windowtext 1.0pt;padding:0cm 0cm 1.0pt 0cm'>

<p class=MsoNormal style='border:none;padding:0cm'><span style='font-size:10.0pt;
line-height:115%;color:#1F497D'>Article 3 - Modalités d’adhésion à la plateforme
Edel Habilitations</span></p>

</div>

<p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
line-height:115%'>Tout Client bénéficiant d’un service de Banque Edel intégré
dans la plateforme Edel Habilitations ouvre droit à la communication d’identifiants
au Représentant Légal permettant l’accès à ses fonctionnalités.</span></p>

<div style='border:none;border-bottom:solid windowtext 1.0pt;padding:0cm 0cm 1.0pt 0cm'>

<p class=MsoNormal style='border:none;padding:0cm'><span style='font-size:10.0pt;
line-height:115%;color:#1F497D'>Article 4 – Gestion des accès </span></p>

</div>

<p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
line-height:115%'>Suite à la création de son compte, l’Utilisateur recevra un
mail confirmant son identifiant (adresse email) et un mot de passe temporaire.
Le mot de passe devra être modifié lors de la première connexion. </span></p>

<p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
line-height:115%'>En cas d’oubli ou de perte du mot de passe, l'Utilisateur a
la possibilité de le réinitialiser directement via la Plateforme
d’Habilitations. Suite à la saisie de son identifiant puis sa demande de
réinitialisation, l'Utilisateur recevra un mail contenant un code à saisir afin
de valider la procédure de réinitialisation de son mot de passe.</span></p>

<div style='border:none;border-bottom:solid windowtext 1.0pt;padding:0cm 0cm 1.0pt 0cm'>

<p class=MsoNormal style='border:none;padding:0cm'><span style='font-size:10.0pt;
line-height:115%;color:#1F497D'>Article 4 - Profils d’habilitation</span></p>

</div>

<p class=MsoNormal style='text-align:justify'><b><span style='font-size:10.0pt;
line-height:115%'>SUPER ADMINISTRATEUR </span></b></p>

<p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
line-height:115%'>Les identifiants du SUPER ADMNISTRATEUR lui permettent un
accès à l’ensemble des Applicatifs Produits dont bénéficie(nt) une ou plusieurs
entités et dont le SUPER ADMINISTRATEUR est le Représentant Légal.</span></p>

<p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
line-height:115%'>Ses prérogatives sont fixées par les CGU applicables à
l’Applicatif Produit.</span></p>

<p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
line-height:115%'>Le SUPER ADMINISTRATEUR a la possibilité d’habiliter un ou
plusieurs ADMINISTRATEURS.</span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-size:10.0pt;
line-height:115%'>ADMINISTRATEUR </span></b></p>

<p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
line-height:115%'>Les prérogatives de l’Administrateur sont fixées par les CGU
applicables à l’Applicatif Produit.</span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-size:10.0pt;
line-height:115%'>HABILITATIONS OPERATIONNELLES</span></b></p>

<p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
line-height:115%'>Le SUPER ADMNISTRATEUR et L’ADMINISTRATEUR ont la faculté de
déléguer certaines fonctionnalités des seuls Applicatifs Produits à tout
collaborateur de leur choix, dans les limites et conditions fixées par les CGU
applicables auxdits Applicatifs Produits. Les collaborateurs du Clients
bénéficiaires d’habilitations opérationnelles n’auront en aucun cas accès à la plateforme
Edel Habilitations.</span></p>

<div style='border:none;border-bottom:solid windowtext 1.0pt;padding:0cm 0cm 1.0pt 0cm'>

<p class=MsoNormal style='border:none;padding:0cm'><span style='font-size:10.0pt;
line-height:115%;color:#1F497D'>Article 5 - Authentification</span></p>

</div>

<p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
line-height:115%'>La consultation ou la réalisation d’opérations est
subordonnée à l’authentification de l’Utilisateur au moyen des identifiants qui
lui ont été communiqués dans le cadre de la création d’une habilitation et tels
que modifiés par ses soins lors de sa première connexion au Service ou cas de
réinitialisation des identifiants.</span></p>

<p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
line-height:115%'>Toute opération initiée après authentification des identifiants
est réputée être initiée par l’Utilisateur.</span></p>

<p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
line-height:115%'>En cas de besoin, le SUPER ADMNISTRATEUR pourra obtenir sur
simple demande auprès de Banque Edel l’historique des habilitations consenties
et des opérations effectuées. En cas de demandes répétées, Banque Edel se
réserve la possibilité de facturer ce service.</span></p>

<div style='border:none;border-bottom:solid windowtext 1.0pt;padding:0cm 0cm 1.0pt 0cm'>

<p class=MsoNormal style='border:none;padding:0cm'><span style='font-size:10.0pt;
line-height:115%;color:#1F497D'>Article 6 - Limites&nbsp;/ prérequis</span></p>

</div>

<p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
line-height:115%'>Dans le cas de services induisant la réalisation d’opérations
à caractère bancaire ou financier, les habilitations devront être conformes aux
procurations bancaires consenties par le Client sur ses comptes ouverts dans
les livres de Banque Edel.</span></p>

<p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
line-height:115%'>L’Utilisateur reconnait en être expressément informé et
s’assurer préalablement à toute création d’habilitation que le bénéficiaire
dispose de la qualité et de la compétence adéquate.</span></p>

<div style='border:none;border-bottom:solid windowtext 1.0pt;padding:0cm 0cm 1.0pt 0cm'>

<p class=MsoNormal style='border:none;padding:0cm'><span style='font-size:10.0pt;
line-height:115%;color:#1F497D'>Article 7 - Responsabilité du délégant</span></p>

</div>

<p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
line-height:115%'>7.1 Le délégant peut être le SUPER ADMINISTRATEUR ou
l’ADMINISTRATEUR dès lors qu’il habilite un collaborateur du Client à accéder
aux Applicatifs Produit ou habiliter lui-même d’autres collaborateurs sur les
Applicatifs Produits.</span></p>

<p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
line-height:115%'>Le SUPER ADMINISTRATEUR et l’ADMINISTRATEUR sont seuls
responsables des habilitations consenties au moyen de la plateforme Edel
Habilitations. La responsabilité de Banque Edel ne saurait ainsi être
recherchée en cas de contestation par le Client d’une habilitation consentie ou
d’une Opération réalisée sur un Applicatif Produit par un délégataire indûment
habilité.</span></p>

<p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
line-height:115%'>7.2 </span><span style='font-size:10.0pt;line-height:115%'>De
manière générale, l’Utilisateur s’engage à assurer la garde et la
confidentialité de ses identifiants en évitant toute imprudence pouvant
favoriser un accès frauduleux au Service.</span></p>

<p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
normal'><span style='font-size:10.0pt'>L’Utilisateur s’engage notamment à
  ne jamais écrire ses identifiants d'une manière qui puisse
être comprise par un tiers et préserver la confidentialité de ses identifiants lorsqu’il
est amené à le composer&nbsp;;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
normal'><span style='font-size:10.0pt'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
normal'><span style='font-size:10.0pt'>La responsabilité de Banque Edel ne
saurait être recherchée au titre des conséquences résultant de la perte des
identifiants, d’une mauvaise utilisation du Service par l’Utilisateur et/ou de
l'utilisation du Service par tout tiers. </span></p>

<p class=MsoNormal style='text-align:justify;line-height:
normal'><span style='font-size:10.0pt'>&nbsp;</span></p>

<div style='border:none;border-bottom:solid windowtext 1.0pt;padding:0cm 0cm 1.0pt 0cm'>

<p class=MsoNormal style='border:none;padding:0cm'><span style='font-size:10.0pt;
line-height:115%;color:#1F497D'>Article 8 - Tarification</span></p>

</div>

<p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
line-height:115%'>L’accès à la plateforme Edel Habilitations est un service mis
gracieusement à la disposition des Clients de Banque Edel.</span></p>

<p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
line-height:115%'>Les frais de connexion pour l’accès à la plateforme Edel
Habilitations (internet, 4G et tout autre mode de connexion à internet, quel
que soit l’opérateur ou le fournisseur d’accès) sont à la charge de
l’Utilisateur.</span></p>

<div style='border:none;border-bottom:solid windowtext 1.0pt;padding:0cm 0cm 1.0pt 0cm'>

<p class=MsoNormal style='border:none;padding:0cm'><span style='font-size:10.0pt;
line-height:115%;color:#1F497D'>Article 9 - Durée - résiliation du service</span></p>

</div>

<p class=MsoNormal style='margin-bottom:0cm;text-align:justify'><span
style='font-size:10.0pt;line-height:115%'>Les présentes CGU régissent la
relation contractuelle entre (i) le Client et Banque Edel d’une part et (ii)
l’Utilisateur et Banque Edel d’autre part, ce pour une durée indéterminée.</span></p>

<p class=MsoNormal style='margin-bottom:0cm;text-align:justify'><span
style='font-size:10.0pt;line-height:115%'>Le Client peut résilier les présentes
CGU à tout moment, moyennant le respect d’un préavis d’un (1) mois notifié par
lettre recommandée avec accusé de réception.</span></p>

<p class=MsoNormal style='margin-bottom:0cm;text-align:justify'><span
style='font-size:10.0pt;line-height:115%'>La suppression de l’habilitation d’un
ADMINISTRATEUR par le SUPER ADMINISTRATEUR au moyen de la plateforme Edel
Habilitations mettra fin de facto aux engagements de l’ADMINISTRATEUR au titre
des présentes.</span></p>

<p class=MsoNormal style='margin-bottom:0cm;text-align:justify'><span
style='font-size:10.0pt;line-height:115%'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;text-align:justify'><b><span
style='font-size:10.0pt;line-height:115%'>Le Client est informé que la résiliation
des présentes CGU est susceptible de compromettre la poursuite de certaines
prestations de Banque Edel au titre des services accessibles via les
Applicatifs produit.</span></b></p>

<p class=MsoNormal style='margin-bottom:0cm;text-align:justify'><span
style='font-size:10.0pt;line-height:115%'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;text-align:justify'><span
style='font-size:10.0pt;line-height:115%'>Banque Edel peut clôturer le
Service à tout moment :</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;text-indent:-18.0pt;line-height:normal;
border:none'><span style='font-size:10.0pt;font-family:"Times New Roman",serif
'>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt'>en cas de non-respect
des présentes CGU,</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;text-indent:-18.0pt;line-height:normal;
border:none'><span style='font-size:10.0pt;font-family:"Times New Roman",serif
'>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt'>si Banque Edel
estime que sa responsabilité peut être engagée du fait des agissements d’un
Utilisateur,</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;text-indent:-18.0pt;line-height:normal;
border:none'><span style='font-size:10.0pt;font-family:"Times New Roman",serif
'>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt'>en cas de
résiliation par le Client ou Banque Edel de l’ensemble des services accessibles
via la plateforme Edel Habilitations, cette dernière devenant sans objet.</span></p>

<p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
line-height:115%'>La résiliation des CGU par Banque Edel sera notifiée au Client
par e-mail et mettra fin avec effet immédiat, sans mise en demeure préalable ni
préavis, aux engagements réciproques des Parties.</span></p>

<p class=MsoNormal style='text-align:justify'><u><span style='font-size:10.0pt;
line-height:115%'>Effet de la résiliation</span></u></p>

<p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
line-height:115%'>En cas de résiliation du Service, le Client pourra obtenir
des informations et/ou réaliser des opérations relatives aux services souscrits
en s’adressant directement à son interlocuteur habituel au sein de Banque Edel.
Ces services pourront toutefois être soumis à une tarification.</span></p>

<div style='border:none;border-bottom:solid windowtext 1.0pt;padding:0cm 0cm 1.0pt 0cm'>

<p class=MsoNormal style='border:none;padding:0cm'><span style='font-size:10.0pt;
line-height:115%;color:#1F497D'>Article 10 - Assistance</span></p>

</div>

<p class=MsoNormal style='margin-bottom:0cm'><span style='font-size:10.0pt;
line-height:115%'>Banque Edel met à disposition de l’Utilisateur une assistance
téléphonique relative à l’utilisation du Service.</span></p>

<p class=MsoNormal style='margin-bottom:0cm'><span style='font-size:10.0pt;
line-height:115%'>Toute demande de désactivation du Service et/ou de modification
des identifiants qui ne pourrait être réalisée directement sur la plateforme
Edel Habilitations, devra impérativement être adressée au Prestataire&nbsp;:</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;text-indent:-18.0pt;line-height:normal;
border:none'><span style='font-size:10.0pt;font-family:"Times New Roman",serif
'>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt'>par </span><span
style='font-size:10.0pt'>téléphone au 05 61 17 34 33 (Service
 disponible du lundi au vendredi, de 8h30 à 17h)</span></p>

<p class=MsoNormal style='margin-bottom:0cm'><span style='font-size:10.0pt;
line-height:115%'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm'><span style='font-size:10.0pt;
line-height:115%'>Toute autre demande relative au Service pourra être adressée:</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;text-indent:-18.0pt;line-height:normal;
border:none'><span style='font-size:10.0pt;font-family:"Times New Roman",serif
'>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt'>par courriel </span><a
href="mailto:produits@banque-edel.fr">
<span style='font-size:10.0pt'>produits@banque-edel.fr</span></a><span
style='font-size:10.0pt'> ou </span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;text-indent:-18.0pt;line-height:normal;
border:none'><span style='font-size:10.0pt;font-family:"Times New Roman",serif
'>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt'>par téléphone au </span><span
style='font-size:10.0pt'>05 61 17 34 33<span style=''> (service
disponible du lundi au vendredi, de 8h30 à 17h).</span></span></p>

<p class=MsoNormal style='text-align:justify;line-height:
normal;border:none'><span style='font-size:10.0pt'>&nbsp;</span></p>

<div style='border:none;border-bottom:solid windowtext 1.0pt;padding:0cm 0cm 1.0pt 0cm'>

<p class=MsoNormal style='border:none;padding:0cm'><span style='font-size:10.0pt;
line-height:115%;color:#1F497D'>Article 11 - Maintenance</span></p>

</div>

<p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
line-height:115%'>Banque Edel pourra procéder à des opérations de maintenance
corrective et évolutive de la plateforme Edel Habilitations à la suite de
l'identification de dysfonctionnements ou de besoins additionnels lors de
l'utilisation de la plateforme Edel Habilitations.</span></p>

<p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
line-height:115%'>En cas d’opérations de maintenance planifiées susceptibles de
perturber le bon fonctionnement de la plateforme Edel Habilitations, Banque
Edelngage à les réaliser en dehors des heures ouvrées lorsque l’opération
de maintenance le permet, et à défaut, d’en informer le Client dans les
meilleurs délais avant l’opération de maintenance.</span></p>

<p class=MsoNormal><span style='font-size:10.0pt;line-height:115%'>Les
engagements de services propres aux produits accessibles via la plateforme Edel
Habilitations sont régis par les contrats spécifiques souscrits par le Client
au titre desdits produits.</span></p>

<div style='border:none;border-bottom:solid windowtext 1.0pt;padding:0cm 0cm 1.0pt 0cm'>

<p class=MsoNormal style='border:none;padding:0cm'><span style='font-size:10.0pt;
line-height:115%;color:#1F497D'>Article 12 - Information de l’Utilisateur</span></p>

</div>

<p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
line-height:115%'>Banque Edel pourra transmettre toute information utile à
l’Utilisateur à l’adresse mail nominative communiquée par l’Utilisateur lors de
l’activation du Service ou telle que modifiée par la suite. </span></p>

<div style='border:none;border-bottom:solid windowtext 1.0pt;padding:0cm 0cm 1.0pt 0cm'>

<p class=MsoNormal style='border:none;padding:0cm'><span style='font-size:10.0pt;
line-height:115%;color:#1F497D'>Article 13 - Protection des données
personnelles</span></p>

</div>

<p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
line-height:115%'>Les termes et expressions commençant par une majuscule auront
aux fins du présent article, l’acception qui leur est donnée par les lois et
réglementations applicables en matière de protection des données personnelles
et de la vie privée, en particulier le Règlement (UE) 2016/679 du 27 avril 2016
dit «&nbsp;Règlement Général sur la Protection des Données&nbsp;» (RGPD).</span></p>

<p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
line-height:115%'>Dans le cadre du Service, le Responsable de Traitement est
Banque Edel.</span></p>

<p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
line-height:115%'>Le traitement a pour finalité principale la création d’accès
à la Plateforme d’habilitations pour le SUPER ADMINISTRATEUR et/ou les
ADMINISTRATEURS habilités, en vue de la consultation d’informations ou
réalisation d’opérations auprès de Banque Edel.</span></p>

<p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
line-height:115%'>Les finalités des traitements, les données collectées et leur
durée de conservation sont les suivantes&nbsp;:</span></p>

<table class=MsoNormalTable border=1 cellspacing=0 cellpadding=0
 style='border-collapse:collapse;border:none'>
 <thead>
  <tr style='height:41.4pt'>
   <td width=188 style='width:141.35pt;border:solid black 1.0pt;padding:0cm 0cm 0cm 0cm;
   height:41.4pt'>
   <p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
   line-height:115%'>Finalités </span></p>
   </td>
   <td width=217 style='width:163.05pt;border:solid black 1.0pt;border-left:
   none;padding:0cm 0cm 0cm 0cm;height:41.4pt'>
   <p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
   line-height:115%'>Types de données</span></p>
   </td>
   <td width=198 style='width:148.4pt;border:solid black 1.0pt;border-left:
   none;padding:0cm 0cm 0cm 0cm;height:41.4pt'>
   <p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
   line-height:115%'>Durée de conservation</span></p>
   </td>
  </tr>
 </thead>
 <tr>
  <td width=188 style='width:141.35pt;border:solid black 1.0pt;border-top:none;
  padding:0cm 0cm 0cm 0cm'>
  <p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
  line-height:115%'>Exécution de la Prestation </span></p>
  </td>
  <td width=217 style='width:163.05pt;border-top:none;border-left:none;
  border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;padding:0cm 0cm 0cm 0cm'>
  <p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
  line-height:115%'>Nom, prénom, adresse email professionnelle </span></p>
  </td>
  <td width=198 style='width:148.4pt;border-top:none;border-left:none;
  border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;padding:0cm 0cm 0cm 0cm'>
  <p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
  line-height:115%'>Durée du présent Accord + 3 mois</span></p>
  </td>
 </tr>
 <tr>
  <td width=188 style='width:141.35pt;border:solid black 1.0pt;border-top:none;
  padding:0cm 0cm 0cm 0cm'>
  <p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
  line-height:115%'>Preuve de l’exécution du contrat et lutte contre la fraude</span></p>
  </td>
  <td width=217 style='width:163.05pt;border-top:none;border-left:none;
  border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;padding:0cm 0cm 0cm 0cm'>
  <p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
  line-height:115%'>Adresse IP, logs de connexion </span></p>
  </td>
  <td width=198 style='width:148.4pt;border-top:none;border-left:none;
  border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;padding:0cm 0cm 0cm 0cm'>
  <p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
  line-height:115%'>Durée du présent Accord + 3 mois </span></p>
  </td>
 </tr>
</table>

<p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
line-height:115%'>&nbsp;</span></p>

<p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
line-height:115%'>Il est expressément précisé que les traitements de données
personnelles spécifiques aux Applicatifs Produits accessibles via la Plateforme
d’habilitations sont détaillés soit par les contrats de souscription
régularisés par le Client soit par les CGU des Applicatifs Produit concernés.</span></p>

<p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
line-height:115%'>En cas de résiliation du Service ou de suppression
d’habilitation sur les Applicatifs Produit, l’Utilisateur est informé que
Banque Edel ne conserve aucune Données Personnelles.</span></p>

<p class=MsoNormal style='margin-bottom:0cm'><span style='font-size:10.0pt;
line-height:115%'>L’Utilisateur dispose de droits concernant les données
personnelles. Il peut exercer ses droits en s’adressant par écrit à l’adresse
suivante&nbsp;: </span></p>

<p class=MsoNormal style='margin-bottom:0cm'><span style='font-size:10.0pt;
line-height:115%'>Banque Edel </span></p>

<p class=MsoNormal style='margin-bottom:0cm'><span style='font-size:10.0pt;
line-height:115%'>Pôle Juridique Compliance
et Contrôle - Madame, Monsieur la/le Délégué(e) à la Protection des Données </span></p>

<p class=MsoNoSpacing><span style='font-size:10.0pt'>60 rue Buissonnière - CS
17601 - 31676 LABEGE Cedex,</span></p>

<p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
line-height:115%'>Ou par courriel à l’adresse&nbsp;: dpo@banque-edel.fr</span></p>

<p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
line-height:115%'>Une notice d’information complète sur le traitement des
Données à caractère personnel&nbsp;par la Banque Edel est disponible sur le
site&nbsp;: https://www.banque-edel.fr/Protection des données</span><span
style='font-family:"Trebuchet MS",sans-serif'></span></p>

<p class=MsoNormal style='text-align:justify'>
  <span style='font-family:"Trebuchet MS",sans-serif'>&nbsp;</span></p>

<div style='border:none;border-bottom:solid windowtext 1.0pt;padding:0cm 0cm 1.0pt 0cm'>

<p class=MsoNormal style='border:none;padding:0cm'><span style='font-size:10.0pt;
line-height:115%;color:#1F497D'>Article 14 - Modifications</span></p>

</div>

<p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
line-height:115%'>Banque Edel se réserve le droit de modifier, en tout
temps, les présentes CGU qui seront soumises à l’acceptation de l’Utilisateur lors
de sa première connexion à la Plateforme suivant leur mise à disposition.</span></p>

<p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
line-height:115%'>Si l’Utilisateur s’oppose à la modification il pourra
résilier son adhésion à la plateforme Edel Habilitations avec effet immédiat
suivant les modalités décrites à l’Article 9.</span></p>

<p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
line-height:115%'>Il est entendu que l’actualisation des CGU corrélative à
l’ajout d’un nouveau service, d’une nouvelle fonctionnalité ou une montée de
version de la plateforme Edel Habilitations n’est pas assimilée à une
modification et sera effectuée et applicable sans notification préalable.</span></p>

<div style='border:none;border-bottom:solid windowtext 1.0pt;padding:0cm 0cm 1.0pt 0cm'>

<p class=MsoNormal style='border:none;padding:0cm'><span style='font-size:10.0pt;
line-height:115%;color:#1F497D'>Article 15 - Propriété intellectuelle</span></p>

</div>

<p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
line-height:115%'>La Plateforme et l’ensemble des éléments y figurant
(informations, données, texte, sons, images, dessins, graphismes, signes distinctifs,
marques, logos) sont la propriété de Banque Edel.</span></p>

<p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
line-height:115%'>L’ensemble de ces éléments est protégé par des droits de
propriété intellectuelle et, à ce titre, est protégé contre toute utilisation
non autorisée par la loi. </span></p>

<p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
line-height:115%'>A ce titre, l’Utilisateur s’interdit&nbsp;:</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;text-indent:-18.0pt;line-height:normal;
border:none'><span style='font-size:10.0pt;font-family:"Times New Roman",serif
'>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt'>d’utiliser ou de
reproduire les noms des Service et/ou logos, seuls ou associés, à quelque titre
que ce soit, et notamment à des fins publicitaires.</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;text-indent:-18.0pt;line-height:normal;
border:none'><span style='font-size:10.0pt;font-family:"Times New Roman",serif
'>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt'>de décompiler ou de
désassembler la Plateforme incluant les Applicatifs Produit, de l’utiliser à des
fins commerciales ou de représentation publique ou de porter atteinte de
quelque manière que ce soit aux droits d’auteur et de propriété intellectuelle
de Banque Edel.</span></p>

<p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
line-height:115%'>&nbsp;</span></p>

<div style='border:none;border-bottom:solid windowtext 1.0pt;padding:0cm 0cm 1.0pt 0cm'>

<p class=MsoNormal style='border:none;padding:0cm'><span style='font-size:10.0pt;
line-height:115%;color:#1F497D'>Article 16 - Preuve – Article 1366 du code
civil</span></p>

</div>

<p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
line-height:115%'>Les fichiers, données, messages et registres informatisés
conservés dans les systèmes informatiques
de chaque Partie seront admis comme preuve des communications et échanges
intervenus entre les Parties, dans la mesure où la Partie dont ils émanent
puisse être identifiée et qu'ils soient établis et conservés dans des
conditions de nature à en garantir l'intégrité.</span></p>

<p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
line-height:115%'>L’authentification de l’Utilisateur au moyen de ses identifiants
vaudra preuve, jusqu’à preuve contraire, notamment:</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;text-indent:-18.0pt;line-height:normal;
border:none'><span style='font-size:10.0pt;font-family:"Times New Roman",serif
'>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt'>de l’imputabilité des
opérations à l’Utilisateur; </span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;text-indent:-18.0pt;line-height:normal;
border:none'><span style='font-size:10.0pt;font-family:"Times New Roman",serif
'>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt'>du consentement de
l’Utilisateur à l’opération et à son contenu ;</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;text-indent:-18.0pt;line-height:normal;
border:none'><span style='font-size:10.0pt;font-family:"Times New Roman",serif
'>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt'>de la date de
l’opération réalisée.</span></p>

<p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
line-height:115%'>&nbsp;</span></p>

<div style='border:none;border-bottom:solid windowtext 1.0pt;padding:0cm 0cm 1.0pt 0cm'>

<p class=MsoNormal style='border:none;padding:0cm'><span style='font-size:10.0pt;
line-height:115%;color:#1F497D'>Article 17 - Règlement des litiges</span></p>

</div>

<p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
line-height:115%'>Les Parties conviennent qu’en cas de difficulté n’ayant pu
être résolue de façon amiable concernant l’exécution ou l’interprétation des
CGU, compétence sera attribuée aux tribunaux du ressort de la Cour d’Appel de
Toulouse.</span></p>

<p class=MsoNormal style='text-align:justify'><span style='font-size:10.0pt;
line-height:115%'>&nbsp;</span></p>

        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green darken-1"
            text
            @click="$emit('click')"
          >
            J'accepte
          </v-btn>
        </v-card-actions>
      </v-card>

</template>

<script>
export default {

};
</script>

<style>

</style>
