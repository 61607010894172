import routesInMenu from './routesLeftMenu';

const routes = [
  ...routesInMenu,
  {
    name: 'ChangePwd',
    path: '/pwd',
    component: () => import(/* webpackChunkName: "ChangePwd" */ '@/views/ChangePwd.vue'),
    meta: { requiresAuth: true },
  },
  {
    // https://router.vuejs.org/guide/essentials/redirect-and-alias.html#redirect
    name: 'Home',
    path: '/',
    redirect: { name: 'Identities' },
  },
  {
    name: 'IdentityDetails',
    path: '/identities/:id',
    component: () => import(/* webpackChunkName: "Identity" */'@/views/Identity.vue'),
    props: true,
    meta: { requiresAuth: true, permissions: ['identities.'] },
  },
  {
    name: 'IdentityCreate',
    path: '/identities/create',
    component: () => import(/* webpackChunkName: "Identity" */'@/views/Identity.vue'),
    props: true,
    meta: { requiresAuth: true, permissions: ['identities.create'] },
  },
  {
    name: 'EntityCreate',
    path: '/entity/:entityKey/:entityId/',
    component: () => import(/* webpackChunkName: "Entity" */'@/views/Entity.vue'),
    props: true,
    meta: { requiresAuth: true, permissions: ['scopes-meta.create'] },
  },
  // {
  //   name: 'User',
  //   path: '/user',
  //   component: () => import('@/views/UserConnected.vue'),
  //   meta: { requiresAuth: true },
  // },
  // {
  //   name: 'Applications',
  //   path: '/applications',
  //   component: () => import('@/views/Applications.vue'),
  //   meta: { requiresAuth: true },
  // },
  // {
  //   name: 'Bundles',
  //   path: '/bundles',
  //   component: () => import('@/views/Bundles.vue'),
  //   meta: { requiresAuth: true },
  // },
  // {
  //   name: 'Profiles',
  //   path: '/profiles',
  //   component: () => import('@/views/Profiles.vue'),
  //   meta: { requiresAuth: true },
  // },
  // {
  //   name: 'Permissions',
  //   path: '/permissions',
  //   component: () => import('@/views/Permissions.vue'),
  //   meta: { requiresAuth: true },
  // },
  {
    path: '*',
    component: () => import(/* webpackChunkName: "NotFound" */ '@/views/NotFound.vue'),
  },
];

export default routes;
