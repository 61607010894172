/*
 * Copyright 2017-2017 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License").
 * You may not use this file except in compliance with
 * the License. A copy of the License is located at
 *
 *     http://aws.amazon.com/apache2.0/
 *
 * or in the "license" file accompanying this file. This file is distributed
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR
 * CONDITIONS OF ANY KIND, either express or implied. See the License for
 * the specific language governing permissions
 * and limitations under the License.
 */
import { Auth } from '@aws-amplify/auth';

import Stack from '@/lib/stack';

import api from '@/lib/api';

const omit = (keys, obj) => Object.fromEntries(Object.entries(obj)
  .filter(([k]) => !keys.includes(k)));

const RESSOURCE_NAME = 'authorizer-context-v2';

// Utils
function timeout(ms) {
  // https://stackoverflow.com/questions/33289726/combination-of-async-function-await-settimeout
  return new Promise((resolve) => { setTimeout(resolve, ms); });
}
async function sleep(fn, ms) {
  await timeout(ms);
  return fn();
}

let tokenRefreshTimer = null;
//  after 1hour, the user has to reconnect
const SESSION_DURATION_INACTIVE_MS = 1 * 60 * 60 * 1000;
function willRefreshToken(commit) {
  if (tokenRefreshTimer) {
    clearTimeout(tokenRefreshTimer);
  }
  // refresh cognito token every 5min
  tokenRefreshTimer = setTimeout(async () => {
    const lastRequestDate = localStorage.getItem('session_lastRequest_date');
    if (lastRequestDate
      && Date.now() - lastRequestDate > SESSION_DURATION_INACTIVE_MS) {
      // after 1hour, the user has to reconnect
      commit(
        'notification/SET_SNACK_DATAS',
        {
          text: 'Votre session a expirée',
          color: 'warn',
        },
        { root: true },
      );
      await Auth.signOut();
      // the Hub will redirect the user to /login if necessary (see router/index.js)
    } else {
      if (lastRequestDate
        && Date.now() - lastRequestDate > SESSION_DURATION_INACTIVE_MS - 2 * 60 * 1000) {
        commit(
          'notification/SET_SNACK_DATAS',
          {
            text: 'Votre session va expirer dans moins de 2 minutes',
            color: 'warn',
          },
          { root: true },
        );
      }
      try {
        const currentSession = await Auth.currentSession();
        const { jwtToken } = currentSession.accessToken;
        api.authenticate(jwtToken);
        willRefreshToken(commit);
      } catch (e) {
      // Network error
        console.warn(e);
        if (e.code === 'NotAuthorizedException') {
          //  message: "Refresh Token has expired"
          commit(
            'notification/SET_SNACK_DATAS',
            {
              text: 'Votre session a expirée',
              color: 'warn',
            },
            { root: true },
          );
          await Auth.signOut();
        } else {
          willRefreshToken(commit);
        }
      }
    }
  }, 5 * 60 * 1000);
}

// Initial state
const initialState = {
  cognito: null,
  hiUser: {
    identity: {
      id: null,
      email: null,
      firstname: null,
      lastname: null,
      bundles: null,
      scopes: null,
      accepted_cgu_version: null,
    },
    permissions: [],
  },
  magasins: [],
  magasinSelected: null,
  applications: [],
  applicationSelected: null,
  citySelected: null, // 'saint chamond',
  bundlesByApp: null,
  myBundlesReal: {},
  bundleOrderByApp: null,
  loading: null,
  errors: new Stack(),
};

// Getters

function hasPermission(permissionsByBundle, perm) {
  return Object.values(permissionsByBundle).some(
    (permissions) => permissions.some((p) => p.key === perm),
  );
}

function getBundleIdsWith(permissionsByBundle, perm) {
  return Object.keys(permissionsByBundle).filter((id) => permissionsByBundle[id].some(
    (permissions) => permissions.key === perm,
  )).map((s) => +s);
}

// Actions
const actions = {
  setMagasinSelected({ commit }, v) {
    commit('SET_MAGASIN', v);
  },
  setCitySelected({ commit }, v) {
    commit('SET_CITY', v);
  },
  setApplicationSelected({ commit }, v) {
    commit('SET_APPLICATION', v);
  },
  hasPermissionIn({ state }, perms) {
    // perms can be ['identities.']
    if (state.hiUser) {
      return Object.values(state.hiUser.permissions).some(
        (permissions) => permissions.some((p) => perms.some((perm) => p.key.startsWith(perm))),
      );
    }
    return false;
  },
  async updateUser({ state }, param) {
    if (state.hiUser.identity.id) {
      await api.update('identities', state.hiUser.identity.id, param);
      state.hiUser.identity = { ...state.hiUser.identity, ...param };
    }
  },
  async updateSelf({ state }, param) {
    if (state.hiUser.identity.id) {
      await api.updateSelf('identities', param);
      state.hiUser.identity = { ...state.hiUser.identity, ...param };
    }
  },
  async signOut({ commit }) {
    try {
      // https://docs.amplify.aws/lib/auth/emailpassword/q/platform/js#sign-out
      await Auth.signOut();
      commit('SET_USER_INFOS', null);
    } catch (error) {
      console.error('error signing out: ', error);
    }
  },
  async loadUserAndCredentials({ state, commit, dispatch }, { router, onSignIn }) {
    try {
      const lastRequestDate = localStorage.getItem('session_lastRequest_date');
      if (!onSignIn
      && lastRequestDate
      && Date.now() - lastRequestDate > SESSION_DURATION_INACTIVE_MS) {
      // after 1hour, the user has to reconnect
        await Auth.signOut();
      } else {
        await Auth.currentAuthenticatedUser()
          .then((user) => commit('SET_USER', user));
        await dispatch('loadCredentials');
        willRefreshToken(commit);
        const route = router.currentRoute;
        const { routes } = router.options;
        const { permissions } = state.hiUser;

        // faire correspondre les permissions avec les routes autorisées
        // dans un tableau myauthorizedroutes
        // rechercher dans ce tableau si la route de redirection est présente
        // renvoie un bool pour dire si on execute la redirection

        const authorizedRoutes = routes.filter((r) => r.meta !== undefined && r.meta.permissions)
          .map((r) => r.meta.permissions[0]);

        const myPerms = Object.keys(permissions).map((app) => permissions[app].map((p) => p.key));

        const myAuthorizedRoutes = authorizedRoutes.filter(
          (aRoute) => myPerms.map((appPerms) => appPerms.find((p) => p.includes(aRoute))),
        );

        const fallBackRoute = routes.filter((r) => r.meta !== undefined && r.meta.permissions)
          .find((r) => r.meta.permissions.includes(myAuthorizedRoutes[0]));

        // add &&!hasUser to solve 'Avoided redundant navigation to current location'
        if (route.query && route.query.redirect) {
          const { redirect } = route.query;
          const redirectRoute = routes.find((r) => redirect === r.path);
          let redirectOk;
          if (redirectRoute) {
            redirectOk = myAuthorizedRoutes
              .some((r) => redirectRoute.meta.permissions.includes(r));
          } else {
            // allow redirect to routes with params (ex: /identities/:id)
            const regexpIdentities = /^\/[a-z]+\/([^/]\d+?)\/?$/i;
            const regexpEntities = /^\/[a-z]+\/([^/]+?)\/([^/]\d+?)\/?$/i;
            redirectOk = regexpIdentities.test(redirect)
              || regexpEntities.test(redirect);
          }
          if (redirectOk) {
            router.push(redirect);
          } else {
            router.push({ name: fallBackRoute.name });
          }
        } else if (route.name === 'Login') {
          router.push({ name: fallBackRoute.name });
        }
      }
    } catch (e) {
      console.warn('loadUserAndCredentials', e);
    }
  },
  async loadCredentials({
    commit, state, dispatch, rootState,
  }, params = {}) {
    if (!state.loading && !state.permissions) {
      commit('SET_LOADING', true);
      try {
        // Récup user
        let myToken = params.jwtToken;
        if (!myToken) {
          const session = await Auth.currentSession();
          if (session) {
            myToken = session.accessToken.jwtToken;
          }
        }
        if (myToken) {
          // commit('SET_USER', user);
          api.authenticate(myToken);
          const response = await api.list(RESSOURCE_NAME);
          commit('SET_USER_INFOS', response.data);

          await dispatch(
            'applications/load',
            { },
            { root: true },
          );
          await dispatch(
            'scopesMeta/load',
            { },
            { root: true },
          );

          const { permissions } = response.data;
          const { scopes, bundles } = response.data.identity;
          const { applications, bundlesByApp } = rootState.applications;
          const bundlesReal = rootState.applications.bundles;

          const hasAllBundles = hasPermission(permissions, 'bundles-identities.create-all');
          if (hasAllBundles) {
            const myBundlesReal = bundles.map((id) => bundlesReal.find((b) => b.id === id))
              .filter((e) => e);
            commit('SET_BUNDLES_BY_APP', { bundlesByApp, myBundlesReal });
            commit('SET_APPLICATIONS', applications);
            const o = applications.reduce((acc, a) => {
              acc[a.id] = 0;
              return acc;
            }, {});
            commit('SET_BUNDLE_ORDER_BY_APP', o);
          } else if (bundles) {
            // filter bundles
            const bundlesIdsWithIdentities = getBundleIdsWith(permissions, 'identities.list');
            const bundlesIdsWithEntities = getBundleIdsWith(permissions, 'scopes-meta.list');
            // admin users can allocate Operator not admin
            const bundlesOnScopes = scopes.reduce((acc, s) => acc.concat(s.bundles || []), []);
            const allBundles = bundlesOnScopes.concat(bundles);

            const myBundlesReal = allBundles.map((id) => bundlesReal.find((b) => b.id === id))
              .filter((e) => e);

            const bundlesIdsWithEntitiesAndIdentities = [
              ...bundlesIdsWithEntities,
              ...bundlesIdsWithIdentities,
            ];
            const orderByApp = myBundlesReal
              .filter((b) => bundlesIdsWithEntitiesAndIdentities.includes(b.id))
              .reduce(
                (acc, b) => {
                // back office user use target_application
                  if (b) {
                    const appIds = b.target_applications
                      ? b.target_applications : [b.default_application];
                    appIds.forEach((appId) => {
                      if (acc[appId]) {
                        if (acc[appId] > b.order) {
                          acc[appId] = b.order;
                        }
                      } else {
                        acc[appId] = b.order;
                      }
                    });
                  }
                  return acc;
                },
                {},
              );

            const bundlesByAppFiltered = {};
            Object.keys(orderByApp).forEach((appIdS) => {
              // not working: list bundles returns superadmin for Admin users
              // const order = bundlesByApp[appId].reduce(
              //   (acc, b) => (acc < b.order ? acc : b.order), 100,
              // );
              // console.log('order', order)

              const appId = +appIdS;
              if (!bundlesByApp[appId]) {
                bundlesByAppFiltered[appId] = {};
              } else {
                bundlesByAppFiltered[appId] = bundlesByApp[appId].filter(
                  (b) => b.order > orderByApp[appId],
                );
              }
            });
            commit('SET_BUNDLES_BY_APP', { bundlesByApp: bundlesByAppFiltered, myBundlesReal });
            commit('SET_APPLICATIONS', Object.keys(orderByApp)
              .map((appId) => applications.find((app) => app.id === +appId))
              .filter((app) => Boolean(app)));
            commit('SET_BUNDLE_ORDER_BY_APP', orderByApp);
          }

          if (state.applications.length > 0) {
            // select the first app by default
            commit('SET_APPLICATION', state.applications[0]);
          }

          commit('SET_LOADING', false);
        } else {
          // no session
          await sleep(() => {
            commit('SET_LOADING', false);
          }, 500);
        }
      } catch (e) {
        console.warn('loadCredentials error', e);
        // no session
        await sleep(() => {
          commit('SET_LOADING', false);
        }, 500);
      }
    }
  },
  // async load({commit})
};

// Mutations
const mutations = {
  SET_USER(state, user) {
    state.cognito = user;

    if (!user) {
      clearTimeout(tokenRefreshTimer);
      api.authenticate(null);
      localStorage.setItem('session_lastRequest_date', null);
    }
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_PERMISSIONS(state, permissions) {
    state.permissions = permissions;
  },
  SET_USER_INFOS(state, hiUser) {
    state.hiUser = hiUser;
  },
  SET_MAGASIN(state, v) {
    state.magasinSelected = v;
  },
  SET_MAGASINS(state, mags) {
    state.magasins = mags;
  },
  SET_APPLICATION(state, v) {
    state.applicationSelected = v;
  },
  SET_CITY(state, v) {
    state.citySelected = v;
  },
  SET_APPLICATIONS(state, apps) {
    apps.sort((a, b) => a.label.localeCompare(b.label));
    state.applications = apps.map((app) => ({
      ...omit('application_client', app),
      scope_meta: app.application_client.scope_meta,
      is_mono_bundle: app.application_client.is_mono_bundle,
    }));
  },
  SET_BUNDLES_BY_APP(state, { bundlesByApp, myBundlesReal }) {
    state.bundlesByApp = bundlesByApp;
    state.myBundlesReal = myBundlesReal;
  },
  SET_BUNDLE_ORDER_BY_APP(state, v) {
    state.bundleOrderByApp = v;
  },
};

const getters = {
  permissions: (state) => {
    if (!state.applicationSelected || !state.hiUser) {
      return [];
    }
    const appId = state.applicationSelected.id;
    const bundleIds = state.myBundlesReal.filter((e) => {
      if (e.target_applications) {
        return e.target_applications.includes(appId);
      }
      return appId === e.default_application;
    }).map((e) => e.id);
    const permissions = bundleIds.reduce((acc, bId) => {
      if (state.hiUser.permissions[bId]) {
        const keys = state.hiUser.permissions[bId].map((p) => p.key);
        keys.forEach((k) => { acc[k] = true; });
      }
      return acc;
    }, {});

    return permissions;
  },
  magasins: (state, get) => {
    const { magasinsAll: magasins } = get;

    magasins.sort((a, b) => {
      const aNaN = Number.isNaN(a.key_value);
      const bNaN = Number.isNaN(b.key_value);

      if (aNaN && bNaN) {
        return a.localeCompare(b);
      }
      if (aNaN && !bNaN) {
        return -1;
      }
      if (bNaN && !aNaN) {
        return 1;
      }
      return +a.key_value - +b.key_value;
    });
    return magasins;
  },
  magasinsAll: (state, _, rootState) => {
    if (!rootState.scopesMeta || !state.hiUser) {
      return [];
    }

    if (!state.applicationSelected
      || !state.applicationSelected.scope_meta
      || state.applicationSelected.scope_meta.length < 1) {
      return [];
    }

    const { mag } = rootState.scopesMeta;
    const { permissions } = state.hiUser;
    const { scopes, bundles } = state.hiUser.identity;
    const hasAllPermissions = hasPermission(permissions, 'scopes.list-all');

    if (hasAllPermissions) {
      const magasins = mag
        .filter((s) => state.applicationSelected.scope_meta.includes(s.key));
      return magasins;
    }
    if (scopes) {
      const bundlesIdsWithIdentities = getBundleIdsWith(permissions, 'identities.list');

      const magasins = scopes
        .filter((s) => state.applicationSelected.id === s.application_id
        && state.applicationSelected.scope_meta.includes(s.key))
        .filter((m) => {
          if (m.bundles && m.bundles.length > 0) {
            return m.bundles.some((b) => bundlesIdsWithIdentities.includes(b));
          }
          return bundles.some((b) => bundlesIdsWithIdentities.includes(b));
        })
        .map((s) => {
          // {id: 2611, key: "codepan", subkey: null,
          // label: "saintchamon", description: null, key_value: "542"}
          const magasin = mag.find((m) => m.key === s.key && m.key_value === s.key_value);
          if (magasin) {
            return { ...s, label: magasin.label };
          }
          return undefined;
        }).filter((m) => m);

      return magasins;
    }
    console.warn(`no scope found ${state.hiUser}`);

    return [];
  },

};

export default {
  namespaced: true,
  state: initialState,
  actions,
  mutations,
  getters,
};
