const routes = [

  {
    name: 'Identities',
    path: '/identities',
    component: () => import(/* webpackChunkName: "Identities" */ '@/views/Identities.vue'),
    meta: { requiresAuth: true, permissions: ['identities.'] },
    title: 'menu.users',
    icon: 'mdi-account',
  },
  {
    name: 'Entities',
    path: '/entities',
    component: () => import(/* webpackChunkName: "Entities" */ '@/views/Entities.vue'),
    // pour limiter l'écran au backoffice
    meta: { requiresAuth: true, permissions: ['scopes-meta.'] },
    title: 'menu.entities',
    icon: 'mdi-store',
  },
  {
    name: 'Extract',
    path: '/extract',
    component: () => import(/* webpackChunkName: "ExtractLogs" */ '@/views/ExtractLogs.vue'),
    meta: { requiresAuth: true, permissions: ['logs.list.', 'export.'] },
    title: 'menu.extract',
    icon: 'mdi-download-lock',
  },

];

export default routes;
