<template>
  <div v-if="user">
    <v-menu v-if="applications.length > 1" offset-y>
      <template v-slot:activator="{ attrs, on }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          class="text-capitalize hidden-sm-and-down rounded"
          color="primary"
          data-test="s-navBarApp"
        >
          <v-icon left class="hidden-sm-and-down">mdi-application</v-icon>
          <span
            v-if="applicationSelected"
            v-text="applicationSelected.label"
            class="subtitle-1 text-capitalize font-weight-light"
            :data-test="'s-selectedApp'"
          ></span>
          <v-icon right>mdi-menu-down</v-icon>
        </v-btn>
      </template>
      <v-list nav dense>
        <v-list-item v-for="app in applicationsRemaining" :key="app.id" link>
          <v-list-item-title
            v-text="app.label"
            @click="setApplicationSelected(app)"
            :data-test="'s-navBarApp-' + app.label"
          />
        </v-list-item>
      </v-list>
    </v-menu>
    <v-chip v-else color="primary" data-test="s-navBarApp" >
      <v-icon left class="hidden-sm-and-down" >mdi-application</v-icon>
      <span
        v-if="applicationSelected"
        v-text="applicationSelected.label"
        class="subtitle-1 text-capitalize font-weight-light"
        :data-test="'s-selectedApp'"
      ></span>
    </v-chip>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  Name: 'NavBarShopSelect',

  computed: {
    ...mapState({
      applications: (state) => state.user.applications,
      applicationSelected: (state) => state.user.applicationSelected,
      user: (state) => state.user.cognito,
    }),
    applicationsRemaining() {
      if (this.applicationSelected) {
        return this.applications.filter(
          (app) => app.id !== this.applicationSelected.id,
        );
      }
      return this.applications;
    },
  },

  methods: {
    ...mapActions('user', ['setApplicationSelected']),
  },
};
</script>
