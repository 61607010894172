const base64js = require('base64-js');

/**
 * Encrypt json to base64
 * @param {object} json
 */
const encryptJsonToBase64 = (json) => base64js.fromByteArray(new TextEncoder('utf-8').encode(JSON.stringify(json)));
/**
 * Decrypt base64 to json
 * @param {string} text
 */
const decryptBase64ToJson = (text) => {
  try {
    return JSON.parse(new TextDecoder('utf-8').decode(base64js.toByteArray(text)));
  } catch (err) {
    return {};
  }
};

export default {
  encryptJsonToBase64,
  decryptBase64ToJson,
};
