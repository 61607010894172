/*
 * Copyright 2017-2017 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License").
 * You may not use this file except in compliance with
 * the License. A copy of the License is located at
 *
 *     http://aws.amazon.com/apache2.0/
 *
 * or in the "license" file accompanying this file. This file is distributed
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR
 * CONDITIONS OF ANY KIND, either express or implied. See the License for
 * the specific language governing permissions
 * and limitations under the License.
 */
// import router from "@/routes.js";
import api from '@/lib/api';

// Initial state
const initialState = {
  // {"id":4363,"key":"codepan","subkey":null,"label":"test","description":null,"key_value":"10310"}
  mag: [],
  // {codepan : { ACHERES: [
  // {"id":4368,"key":"codepan","subkey":"city",
  // "label":"acheres","description":null,"key_value":"0778"}]
  entityCodesByKeyThenCity: {},
  loading: false,
  // {"id":4365,"key":"codepan","subkey":"city","label":"AJACCIO", "key_value":"10310"}
  cities: [],
};

// Getters
const getters = {

};

// Actions
const actions = {
  async getEntity(_, entity) {
    const payload = {
      where: {
        ...entity,
      },
    };
    const response = await api.list('scopes-meta', payload);
    const myEntity = {
      ...entity,
      items: response.data.items,
      ...response.data.items.reduce(
        (acc, e) => {
          acc[e.subkey || 'label'] = e.label;
          return acc;
        },
        {},
      ),
    };
    return myEntity;
  },
  async saveEntity({ commit }, myEntity) {
    const {
      // eslint-disable-next-line camelcase
      items, key, key_value, label, city,
    } = myEntity;
    const existingLabel = items.find((i) => i.subkey === null);
    const modifications = {
      create: [], update: [], deleteIds: [],
    };
    if (existingLabel) {
      if (existingLabel.label !== label) {
        await api.update('scopes-meta', existingLabel.id, { label });
        existingLabel.label = label;
        modifications.update.push(existingLabel);
      }
    } else {
      const resp = await api.create('scopes-meta', { key, key_value, label });
      modifications.create.push(resp.data);
    }
    // city id: 4358 & 4359 4361
    const existing = items.find((i) => i.subkey === 'city');
    if (existing) {
      if (!city) {
        await api.hardDelete('scopes-meta', { id: existing.id });
        modifications.deleteIds.push(existing.id);
      } else if (existing.label !== city) {
        await api.update('scopes-meta', existing.id, { label: city });
        existing.label = city;
        modifications.update.push(existing);
      }
    } else if (city) {
      const resp = await api.create('scopes-meta', {
        key, key_value, label: city, subkey: 'city',
      });
      modifications.create.push(resp.data);
    }
    commit('UPDATE', modifications);
  },
  async load({ commit }) {
    const payload = {
      perPage: 0,
      // page: params.page || this.page,

    };

    const response = await api.list('scopes-meta', payload);
    // remove label: Code Panonceau
    const data = response.data.items.reduce((acc, i) => {
      if (i.subkey) {
        // {id: 2611, key: "codepan", subkey: null,
        // label: "saintchamon", description: null, key_value: "542"}
        if (i.subkey === 'city') {
          acc.cities.push(i);
        }
      } else {
        acc.mags.push(i);
      }

      return acc;
    }, {
      mags: [],
      cities: [],

    });
    // const mags = response.data.items.filter((d) => d.subkey !== 'label');
    commit('SET', data);
  },

  async import(_, data) {
    await api.post('import-csv', '', null, data);
  },

};

// Mutations
const mutations = {

  UPDATE(state, modifications) {
    if (modifications.deleteIds) {
      const cities = [...state.cities];
      modifications.deleteIds.forEach((id) => {
        const i = cities.findIndex((c) => c.id === id);
        if (i >= 0) {
          cities.splice(i, 1);
        }
      });
      state.cities = cities;
    }
    modifications.create.forEach((d) => {
      if (d.subkey === 'city') {
        state.cities.push(d);
      } else {
        state.mag.push(d);
      }
    });
    modifications.update.forEach((d) => {
      let array;
      if (d.subkey === 'city') {
        array = state.cities;
      } else {
        array = state.mag;
      }
      const e = array.find((c) => c.id === d.id);
      if (e) {
        e.label = d.label;
      }
    });
  },

  SET(state, data) {
    state.mag = data.mags;
    state.entityCodesByKeyThenCity = data.cities.reduce((acc, e) => {
      const { key } = e;
      const label = e.label.toUpperCase();
      if (!acc[key]) {
        acc[key] = {};
      }
      if (acc[key][label]) {
        acc[key][label].push(e);
      } else {
        acc[key][label] = [e];
      }
      return acc;
    }, {});
    state.cities = data.cities;
  },

};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
