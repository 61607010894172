<template>
  <v-footer
    app
    color="grey darken-2"
    dense
    collapse-on-scroll
    class="font-weight-medium white--text text-caption"
  >
   <span>
       <!--  <v-btn text rounded to="/changelog" > -->
      <v-icon small left color="white">mdi-tag-outline</v-icon>
      v{{ version }}
    </span>
    <v-spacer />
    <span role="img" aria-label="copyright">&copy;</span>
    <span> {{ new Date().getFullYear() }}</span>
    <v-btn text plain small @click="dialog = true" color="white">
       CGU
    </v-btn>

    <v-dialog v-model="displayCgu" persistent  >
      <cgu @click="acceptCgu" />
    </v-dialog>
  </v-footer>
</template>

<!-- <v-col class="text-center" cols="12">
  {{ new Date().getFullYear() }} — <strong>{{title}}</strong>
</v-col> -->

<script>
import { mapState, mapActions } from 'vuex';
import Cgu from './Cgu.vue';

const CGU_VERSION = '1';
export default {
  components: { Cgu },
  name: 'BottomNavigation',

  data() {
    return {
      title: process.env.VUE_APP_TITLE,
      version: process.env.VUE_APP_VERSION,
      dialog: false,
    };
  },
  computed: {
    ...mapState({
      hiUser: (state) => state.user.hiUser,
    }),
    displayCgu:
    {
      get() {
        return (this.dialog || this.hasNotReadCgu());
      },
      set() {
        // [Vue warn]: Computed property "displayCgu" was assigned to but it has no setter.
      },
    },
  },
  methods: {
    ...mapActions('user', [
      'updateSelf',
    ]),
    async acceptCgu() {
      const payload = {
        accepted_cgu_version: CGU_VERSION,
      };
      if (this.hasNotReadCgu()) {
        await this.updateSelf(payload);
      }
      this.dialog = false;
    },
    hasNotReadCgu() {
      return this.hiUser
      && this.hiUser.identity
      && this.hiUser.identity.id
      && this.hiUser.identity.accepted_cgu_version !== CGU_VERSION;
    },
  },
};
</script>

<style>
</style>
