<template>
  <div class="text-center">
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" :loading="loading"
        data-test="bt-nav-user" icon>
        <!-- {{ user ? user.username : "non connecté" }} -->
          <v-icon>mdi-account-circle</v-icon>
        </v-btn>
      </template>
      <v-list link dense>
        <v-list-item data-test="li-user-infos">
          <v-list-item-content>
            <v-list-item-title>
              {{ user ? user.firstname : "non connecté" }}
              {{ user ? user.lastname : "non connecté" }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ user ? user.username : "non connecté" }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item @click="signOut" v-if="user">
          <v-list-item-title>Se déconnecter </v-list-item-title>
        </v-list-item>
        <v-list-item v-if="user && user.signInUserSession" to="/pwd"
        data-test="bt-nav-change-pwd" >
        <!-- user coming from Alertel do not have user.signInUserSession -->
          <v-list-item-title>Changer son mot de passe </v-list-item-title>
        </v-list-item>
        <v-list-item v-if="!user" to="/login">
          <v-list-item-title>Se connecter </v-list-item-title>
        </v-list-item>
        <v-list-item disabled :href="helpUrl" target="_blank" tag="li"
        data-test="bt-nav-help" >
          <v-icon  class="mr-2">mdi-help-circle</v-icon>
          <v-list-item-title> Document de formation à venir </v-list-item-title>
          <v-icon small class="ml-2">mdi-open-in-new</v-icon>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';

export default {
  computed: {
    ...mapState({
      user: (state) => state.user.cognito,
      loading: (state) => state.user.loading,
    }),
    ...mapGetters('user', [
      'magasins']),
    helpUrl() {
      if (process.env.VUE_APP_API_ENDPOINT) {
        // TODO to delete: wait documents
        return undefined;
      }
      const hasGalec = this.magasins.some((e) => e.key === 'galec');
      const urlRoot = process.env.VUE_APP_API_ENDPOINT;
      if (hasGalec) {
        return `${urlRoot}/assets/userManual/edelHabilitations-galec-v1.pdf`;
      }
      return `${urlRoot}/assets/userManual/edelHabilitations-v1.pdf`;
    },
  },

  methods: {
    ...mapActions('user', ['signOut']),
  },
};
</script>
