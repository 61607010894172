<template>
  <v-app-bar clipped-left app class="elevation-0 app-bar" color="white">
    <!-- <v-app-bar-nav-icon
      v-if="!['Login'].includes($route.name)"
      @click.stop="$emit('toggle-mobiledrawer')"
      class="hidden-md-and-up"
    /> -->
    <v-toolbar-title class="headline noselect pl-1">
      <router-link to="/" custom v-slot="{ navigate }" style="cursor: pointer;">
      <!-- https://next.router.vuejs.org/guide/migration/#removal-of-append-prop-in-router-link -->
        <span @click="navigate" @keypress.enter="navigate" role="link" tabindex="-1" >
          <span  class="primary--text">Edel</span>
          <span class="font-weight-light secondary--text"> Habilitations</span>
        </span>
      </router-link>
      <!-- <span class="subtitle-1 grey--text hidden-sm-and-down">&nbsp; Banque Edel</span> -->
    </v-toolbar-title>
    <v-spacer />
    <div v-if="!['login'].includes($route.name)" class="mr-3">
      <nav-bar-app-select />
    </div>
     <div v-if="permissions['scopes.list-all'] || permissions['identities.list-all']" class="mr-3">
      <nav-bar-city-select @input="setCitySelected" />
     </div>
    <div v-if="user" class="mr-3">
      <nav-bar-shop-select v-model="entitySelected" filled />
    </div>
    <user-menu />
  </v-app-bar>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import UserMenu from '../User/UserMenu.vue';
import NavBarAppSelect from './NavBarAppSelect.vue';
import NavBarCitySelect from './NavBarCitySelect.vue';
import NavBarShopSelect from './NavBarShopSelect.vue';

export default {
  Name: 'TopNavigation',
  components: {
    NavBarCitySelect,
    NavBarAppSelect,
    NavBarShopSelect,
    UserMenu,
  },

  computed: {
    ...mapState({

      user: (state) => state.user.cognito,
      magasinSelectedRO: (state) => state.user.magasinSelected,

    }),
    ...mapState('user', [
      'applicationSelected',
      'hiUser',
    ]),
    ...mapGetters('user', ['permissions']),

    entitySelected: {
      get() {
        return this.magasinSelectedRO;
      },
      set(v) {
        this.setMagasinSelected(v);
      },
    },
  },

  methods: {
    ...mapActions('user', ['setMagasinSelected', 'setCitySelected']),

  },
  watch: {
    applicationSelected() {
      this.setCitySelected(null);
    },

  },

};
</script>

<style>
.app-bar .v-toolbar__content {
  border-bottom: solid 1px #e0e0e0;
}

.theme--light.v-app-bar.v-toolbar.v-sheet {
  background-color: #fff;
}
</style>
