import Vue from 'vue';
import Vuex from 'vuex';

import applications from './modules/applications';
import identities from './modules/identities';
import notification from './modules/notification';
import profiles from './modules/profiles';
import scopes from './modules/scopes';
import scopesMeta from './modules/scopesMeta';
import user from './modules/userConnected';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    applications,
    identities,
    profiles,
    scopes,
    scopesMeta,
    user,
    notification,
  },
});
