/* eslint-disable no-underscore-dangle */
import { extend, configure, setInteractionMode } from 'vee-validate';
import { required, email, min } from 'vee-validate/dist/rules';

export default (i18n) => {
  setInteractionMode('eager');

  configure({
    defaultMessage: (field, values) => {
      values._field_ = i18n.t(`fields.${field}`);
      return i18n.t(`validation.${values._rule_}`, values);
    },
  });

  extend('required', required);
  extend('email', email);
  extend('min', min);
};
