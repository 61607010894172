<template>
  <v-snackbar
    :value="snack.visible"
    timeout="3000"
    :color="snack.color"
    top
    right
    @input="snackbarClose"
    data-test="p-notification"
  >
    {{ snack.t ? $t(snack.t) : snack.text }}
    <template v-slot:action="{ attrs }">
      <v-btn icon v-bind="attrs" @click="snackbarClose">
        <v-icon>mdi-close-circle</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  computed: {
    snack() {
      return this.$store.getters['notification/snackbarDatas'];
    },
  },
  methods: {
    snackbarClose() {
      this.$store.commit('notification/CLOSE_SNACKBAR');
    },
  },
};
</script>
