const {
  VUE_APP_AWS_CLIENT_DOMAIN, VUE_APP_AWS_USER_POOL_WEB_CLIENT_ID,
  VUE_APP_AWS_HL_CLIENT_DOMAIN, VUE_APP_AWS_HL_USER_POOL_WEB_CLIENT_ID,
} = process.env;

const context = new Map();
context.set(VUE_APP_AWS_CLIENT_DOMAIN, {
  userPoolWebClientId: VUE_APP_AWS_USER_POOL_WEB_CLIENT_ID,

});
context.set(VUE_APP_AWS_HL_CLIENT_DOMAIN, {
  userPoolWebClientId: VUE_APP_AWS_HL_USER_POOL_WEB_CLIENT_ID,

});

export default {
  get(type) {
    const urls = context.get(window.location.origin);
    if (urls) {
      return urls[type];
    }
    if (localStorage.getItem('cy-client') === 'HL') {
      return context.get(VUE_APP_AWS_HL_CLIENT_DOMAIN)[type];
    }
    return context.get(VUE_APP_AWS_CLIENT_DOMAIN)[type];
  },
};
