// File inspired from : https://docs.amplify.aws/lib/auth/start/q/platform/js#re-use-existing-authentication-resource

import multiClient from './lib/multiClient';

// change format to avoid "Error: Amplify has not been configured correctly."
const awsmobile = {
  Auth: {
    region: process.env.VUE_APP_AWS_REGION,
    userPoolId: process.env.VUE_APP_AWS_USER_POOL_ID,
    userPoolWebClientId: multiClient.get('userPoolWebClientId'),
    oauth: {
      scope: ['email', 'profile', 'openid'],
      redirectSignIn: process.env.VUE_APP_AWS_REDIRECT_SIGNIN,
      redirectSignOut: process.env.VUE_APP_AWS_REDIRECT_SIGNOUT,
      responseType: 'code',
    },
    clientMetadata: {
      application_id: '10000',
    },
  },
};

export default awsmobile;
