<template>
  <v-autocomplete
    v-model="selected"
    :items="citiesSorted"
    :auto-select-first="true"
    label="Ville"
    color="primary"
    item-color="secondary"
    hide-details
    dense
    offset-y
    outlined
    filled
    class="rounded"
    return-object
    @input="input"
    data-test="s-navBarCity"
  >
  <template v-slot:[`selection`]="{ item }">
      <span > {{ item  }} </span>
    </template>

  </v-autocomplete>
</template>

<script>
import { mapState } from 'vuex';

export default {

  data() {
    return {
      selected: null,
    };
  },

  computed: {
    ...mapState({
      entityCodesByKeyThenCity: (state) => state.scopesMeta.entityCodesByKeyThenCity,
      applicationSelected: (state) => state.user.applicationSelected,

      citiesSorted() {
        if (this.applicationSelected
        && this.applicationSelected.scope_meta
        && this.applicationSelected.scope_meta.length > 0) {
          const result = this.applicationSelected.scope_meta.map(
            (key) => {
              if (this.entityCodesByKeyThenCity[key]) {
                return Object.keys(this.entityCodesByKeyThenCity[key]);
              }
              return [];
            },
          ).reduce((acc, array) => acc.concat(array));
          return [...new Set(result)].sort();
        }

        return [];
      },

      // stores: (state) => state.targets.targets,
    }),

  },
  methods: {
    input(e) {
      this.$emit('input', e);
    },
  },

};
</script>

<style>

</style>
