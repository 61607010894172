/**
 * Simple Stack
 *
 * new Stack({ maxSize: 10 })
 * @export
 * @class Stack
 */
export default class Stack {
  constructor(options = {}) {
    this.items = [];
    this.maxSize = options.maxSize || 10;
  }

  /**
   * Add any object to Stack
   *
   * @param {*} any
   * @memberof Stack
   */
  add(any) {
    this.items.push(any);
    this.$clear();
  }

  $clear() {
    for (let index = this.limit; index < this.items.length; index += 1) {
      this.items.pop();
    }
  }

  get() {
    return this.items;
  }
}
